require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/06015457019acdc895570cb6ee4183f7/code.js', () => { 

BackendlessUI.Functions.Custom['fn_06015457019acdc895570cb6ee4183f7'] = async function fn_06015457019acdc895570cb6ee4183f7(list) {
var itemExists, item, existingItems;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  existingItems = [];

return (await asyncListFilter(list, async (item) => {
   itemExists = (existingItems.includes(item));
  if (!itemExists) {
    addItemToList(existingItems, item);
  }


 return (!itemExists);
}))
}

})
define('./functions/0bf6dc0c7267d782feb3acbfe09b8e7c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c'] = async function fn_0bf6dc0c7267d782feb3acbfe09b8e7c(key) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (getObjectProperty((await ( async function (language) { return BackendlessUI.I18n.getDictionary(language) })(((function () { return BackendlessUI.I18n.getLanguage() })()))), (String('lang_') + String(key))))
}

})
define('./functions/12d3035a93372f43c859416bf8a2ec89/code.js', () => { 

BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89'] = async function fn_12d3035a93372f43c859416bf8a2ec89(oldfile, newfile) {
function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  if (newfile != oldfile && oldfile != null) {
    await Backendless.Files.remove(encodePath(oldfile));
  }

return newfile
}

})
define('./functions/15c58d25adbd4386f92be77926950ab5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_15c58d25adbd4386f92be77926950ab5'] = function fn_15c58d25adbd4386f92be77926950ab5(pw1, pw2) {
return (pw1 == pw2 && (pw1.match((new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,250}$', 'g')))))
}

})
define('./functions/27f111cc2ab2f4d4b84fe4fe372b9af2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_27f111cc2ab2f4d4b84fe4fe372b9af2'] = function fn_27f111cc2ab2f4d4b84fe4fe372b9af2(level) {
var level, page, path, returnValue2, sublist;

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  page = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  level = level;
  path = [((function () { return BackendlessUI.Navigator.getCurrentPageName() })())];
  if (!(typeof level === 'number' && !isNaN(level))) {
    level = 0;
  }
  if (page.indexOf('_') !== -1) {
    path = page.split('_');
  }
  removeItemInList(path, 'admin', '');
  removeItemInList(path, 'landing', '');
  if (page.startsWith('admin')) {
    returnValue2 = 'admin';
  } else {
    if (level == 0) {
      returnValue2 = 'landing';
    } else {
      returnValue2 = '';
    }
  }
  if (level > 0) {
    sublist = path.slice(0, level).join('_');
    if (returnValue2.length > 0) {
      returnValue2 = String(returnValue2) + String(String('_') + String(sublist));
    } else {
      returnValue2 = sublist;
    }
  }

return returnValue2
}

})
define('./functions/28b9f8ff56eaf2b3acc180ca591e924e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_28b9f8ff56eaf2b3acc180ca591e924e'] = function fn_28b9f8ff56eaf2b3acc180ca591e924e(file) {
var fileExtension, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}


  fileExtension = (getObjectProperty(file, 'name')).split('.').slice(-1)[0];
  if (fileExtension.length == 0) {
    fileExtension = subsequenceFromStartLast((getObjectProperty(file, 'type')), (((getObjectProperty(file, 'type')).lastIndexOf('/') + 1 + 1) - 1));
    if (fileExtension == 'vnd.apple.keynote') {
      fileExtension = 'key';
    } else if (fileExtension == 'vnd.openxmlformats-officedocument.presentationml.presentation') {
      fileExtension = 'pptx';
    } else if (fileExtension == 'vnd.ms-powerpoint') {
      fileExtension = 'ppt';
    }
  }

return ([(generateUUID().short()),'.',fileExtension].join(''))
}

})
define('./functions/3a8bffe35f8d7e7c940c0d0e76ca4941/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'] = async function fn_3a8bffe35f8d7e7c940c0d0e76ca4941(pageData, roleName, appData) {
var error, currentUserRoles, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = null;
  try {
    if (typeof pageData === 'undefined') {
      throw (new Error('Aborting - Unable to establish if user is logged in. Datastore not defined!'))
    }
    if (!(await Backendless.UserService.isValidLogin())) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
    } else {
      if ((getObjectProperty(pageData, 'currentUser')) !== null && !Array.isArray((getObjectProperty(pageData, 'currentUser'))) && (typeof (getObjectProperty(pageData, 'currentUser')) === 'object')) {
        currentUser = (getObjectProperty(pageData, 'currentUser'));
      } else {
        if ((getObjectProperty(appData, 'currentUser')) !== null && !Array.isArray((getObjectProperty(appData, 'currentUser'))) && (typeof (getObjectProperty(appData, 'currentUser')) === 'object')) {
          currentUser = (getObjectProperty(appData, 'currentUser'));
        } else {
          currentUser = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
          if (!(currentUser !== null && !Array.isArray(currentUser) && (typeof currentUser === 'object'))) {
            currentUser = (await Backendless.UserService.getCurrentUser(false));
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
          }
          appData['currentUser'] = currentUser;
        }
        pageData['currentUser'] = currentUser;
        await ( async function (language) { return BackendlessUI.I18n.setLanguage(language) })((getObjectProperty(currentUser, 'blUserLocale')));
      }
      if (!(Array.isArray((getObjectProperty(appData, 'currentUserRoles'))))) {
        currentUserRoles = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUserRoles'));
        if (!(Array.isArray(currentUserRoles))) {
          currentUserRoles = (await Backendless.UserService.getUserRoles());
        }
        appData['currentUserRoles'] = currentUserRoles;
      } else {
        currentUserRoles = (getObjectProperty(appData, 'currentUserRoles'));
      }
      if (typeof roleName === 'string') {
        if (!(currentUserRoles.includes(roleName))) {
          if (currentUserRoles.includes('Administrator')) {
            (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin', undefined);
          } else if (currentUserRoles.includes('Instructor')) {
            (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);
          } else {
            (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
          }
        }
      }
    }

  } catch (error) {
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((getObjectProperty(error, 'message')));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  }

}

})
define('./functions/4ccdaa2e1646d6ad256b6a533d2d384e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e'] = function fn_4ccdaa2e1646d6ad256b6a533d2d384e(timestamp, clock) {
var returnVal;

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  if (timestamp == null) {
    returnVal = 'n/a';
  } else {
    returnVal = clock ? subsequenceFirstFromEnd(((new Date(timestamp)).toLocaleString()), 3) : ((new Date(timestamp)).toLocaleDateString());
  }

return returnVal
}

})
define('./functions/4e95111538c25577e3d842139bfd1717/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4e95111538c25577e3d842139bfd1717'] = function fn_4e95111538c25577e3d842139bfd1717(fieldValue, searchString) {
var returnValue2;


  if (typeof fieldValue === 'number' && !isNaN(fieldValue)) {
    returnValue2 = (fieldValue.indexOf(searchString) !== -1);
  } else if (!(fieldValue === null)) {
    returnValue2 = ((fieldValue.toUpperCase()).indexOf((searchString.toUpperCase())) !== -1);
  } else {
    returnValue2 = false;
  }

return returnValue2
}

})
define('./functions/4f66630386e89ae2e2ed7b71345a3989/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4f66630386e89ae2e2ed7b71345a3989'] = function fn_4f66630386e89ae2e2ed7b71345a3989(number, decimals) {
var returnVar, factor;


  if (!(typeof number === 'undefined')) {
    factor = Math.pow(10,decimals);
    returnVar = Math.round(number * factor) / factor;
  } else {
    returnVar = null;
  }

return returnVar
}

})
define('./functions/521585cb30311713eddf039d55fae03d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'] = async function fn_521585cb30311713eddf039d55fae03d(DataModel, inTableName, inWhereClause, inHavingClause, inRelationList, inPropertyList, inExcludePropList, inRelDepth, inRelPageSize, inSortBy, inGroupBy, inDistinct, inFileRefPrefix, loadPage) {
var loadPage, pageSize, pageOffset, tableSize, distinct, whereClause, tableName, fileRefPrefix, groupBy, sortBy, relPageSize, relDepth, excludePropList, propertyList, relationList, havingClause, loadedData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function initialize() {
  tableName = (getObjectProperty(DataModel, 'tableName'));
  if (tableName == null && inTableName != null) {
    tableName = inTableName;
    DataModel['tableName'] = tableName;
  }
  whereClause = (getObjectProperty(DataModel, 'whereClause'));
  if (whereClause == null && inWhereClause != null) {
    whereClause = inWhereClause;
    DataModel['whereClause'] = whereClause;
  }
  havingClause = (getObjectProperty(DataModel, 'havingClause'));
  if (havingClause == null && inHavingClause != null) {
    havingClause = inHavingClause;
    DataModel['havingClause'] = havingClause;
  }
  relationList = (getObjectProperty(DataModel, 'relationList'));
  if (relationList == null && inRelationList != null) {
    relationList = inRelationList;
    DataModel['relationList'] = relationList;
  }
  propertyList = (getObjectProperty(DataModel, 'propertyList'));
  if (propertyList == null && inPropertyList != null) {
    propertyList = inPropertyList;
    DataModel['propertyList'] = propertyList;
  }
  excludePropList = (getObjectProperty(DataModel, 'excludePropList'));
  if (excludePropList == null && inExcludePropList != null) {
    excludePropList = inExcludePropList;
    DataModel['excludePropList'] = excludePropList;
  }
  relDepth = (getObjectProperty(DataModel, 'relDepth'));
  if (relDepth == null && inRelDepth != null) {
    relDepth = inRelDepth;
    DataModel['relDepth'] = relDepth;
  }
  relPageSize = (getObjectProperty(DataModel, 'relPageSize'));
  if (relPageSize == null && inRelPageSize != null) {
    relPageSize = inRelPageSize;
    DataModel['relPageSize'] = relPageSize;
  }
  sortBy = (getObjectProperty(DataModel, 'sortBy'));
  if (sortBy == null && inSortBy != null) {
    sortBy = inSortBy;
    DataModel['sortBy'] = sortBy;
  }
  groupBy = (getObjectProperty(DataModel, 'groupBy'));
  if (groupBy == null && inGroupBy != null) {
    groupBy = inGroupBy;
    DataModel['groupBy'] = groupBy;
  }
  distinct = (getObjectProperty(DataModel, 'distinct'));
  if (distinct == null && inDistinct != null) {
    distinct = inDistinct;
    DataModel['distinct'] = distinct;
  }
  fileRefPrefix = (getObjectProperty(DataModel, 'fileRefPrefix'));
  if (fileRefPrefix == null && inFileRefPrefix != null) {
    fileRefPrefix = inFileRefPrefix;
    DataModel['fileRefPrefix'] = fileRefPrefix;
  }
  pageOffset = (getObjectProperty(DataModel, 'pageOffset'));
  if (pageOffset == null) {
    pageOffset = 0;
    DataModel['pageOffset'] = pageOffset;
  }
  pageSize = (getObjectProperty(DataModel, 'pageSize'));
  if (pageSize == null) {
    pageSize = 10;
    DataModel['pageSize'] = pageSize;
  }
  loadPage = loadPage;
  if (loadPage == null) {
    loadPage = 1;
  }
  DataModel['currentPage'] = loadPage;
  tableSize = (getObjectProperty(DataModel, 'tableSize'));
  if (tableSize == null) {
    tableSize = (await Backendless.Data.of(tableName).getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setDistinct(distinct)));
    DataModel['tableSize'] = tableSize;
  }
  DataModel['pageCount'] = (Math.ceil(tableSize / pageSize));
  pageOffset = pageSize * (loadPage - 1);
}

/**
 * Describe this function...
 */
async function loadData() {
  loadedData = (await Backendless.Data.of(tableName).find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setHavingClause(havingClause).setProperties(propertyList).excludeProperties(excludePropList).setRelated(relationList).setRelationsDepth(relDepth).setRelationsPageSize(relPageSize).setSortBy(sortBy).setGroupBy(groupBy).setPageSize(pageSize).setOffset(pageOffset).setDistinct(distinct).setFileReferencePrefix(fileRefPrefix)));
}


  await initialize();
  await loadData();

return loadedData
}

})
define('./functions/5b7b17c2e6f9eaa9839412a702eaa2ca/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5b7b17c2e6f9eaa9839412a702eaa2ca'] = async function fn_5b7b17c2e6f9eaa9839412a702eaa2ca(objectList, property, value) {
var object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



return ((await asyncListFilter(objectList, async (object) => {


 return ((getObjectProperty(object, property)) == value);
}))[0])
}

})
define('./functions/601d78964cc4bd6784d831c87d94f8ee/code.js', () => { 

BackendlessUI.Functions.Custom['fn_601d78964cc4bd6784d831c87d94f8ee'] = async function fn_601d78964cc4bd6784d831c87d94f8ee(objectList, property, value) {
var object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}



return (await asyncListFilter(objectList, async (object) => {


 return ((getObjectProperty(object, property)) == value);
}))
}

})
define('./functions/7d3832eea52ef14867f338be94ef03a3/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7d3832eea52ef14867f338be94ef03a3'] = function fn_7d3832eea52ef14867f338be94ef03a3(list, property) {
var result, tempItem, item;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  result = [];
  for (var item_index in list) {
    item = list[item_index];
    tempItem = ({  });
    tempItem[property] = item;
    addItemToList(result, tempItem);
  }

return result
}

})
define('./functions/a5d57ea77d7e8d1f69662c056f663e02/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a5d57ea77d7e8d1f69662c056f663e02'] = function fn_a5d57ea77d7e8d1f69662c056f663e02(code) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (Object.keys(({ [`code`]: code })).reduce((template, key) => template = template.replace(':' + key, ({ [`code`]: code })[key]), (String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'surveyURL')) + String('?page=report&code=:code'))))
}

})
define('./functions/b8ee885c216cab297688fe5b3d272611/code.js', () => { 

BackendlessUI.Functions.Custom['fn_b8ee885c216cab297688fe5b3d272611'] = async function fn_b8ee885c216cab297688fe5b3d272611(level) {
var level, page, path, returnValue2, sublist, item;

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  page = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })());
  level = level;
  path = [((function () { return BackendlessUI.Navigator.getCurrentPageName() })())];
  if (!(typeof level === 'number' && !isNaN(level))) {
    level = 0;
  }
  if (page.indexOf('_') !== -1) {
    path = page.split('_');
  }
  removeItemInList(path, 'admin', '');
  removeItemInList(path, 'landing', '');
  if (page.startsWith('admin')) {
    returnValue2 = 'breadcrumb_admin';
  } else {
    if (level == 0) {
      returnValue2 = 'breadcrumb_home';
    } else {
      returnValue2 = 'breadcrumb';
    }
  }
  if (level > 0) {
    sublist = path.slice(0, level).join('_');
    returnValue2 = String(returnValue2) + String(String('_') + String(sublist));
  }

return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c'](returnValue2))
}

})
define('./functions/cc83f03b6e47613d43acc4543652847f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_cc83f03b6e47613d43acc4543652847f'] = function fn_cc83f03b6e47613d43acc4543652847f(array, value) {
var return2, error;


  try {
    return2 = false;
    if (Array.isArray(array)) {
      if (array.includes(value)) {
        return2 = true;
      }
    }

  } catch (error) {
    console.log(error);

  }

return return2
}

})
define('./functions/ccb7b8ebebd1c16ea1873e0f79b8cd0c/code.js', () => { 

BackendlessUI.Functions.Custom['fn_ccb7b8ebebd1c16ea1873e0f79b8cd0c'] = async function fn_ccb7b8ebebd1c16ea1873e0f79b8cd0c(objectList, propValue) {
var objectExists, matchingProperty, objectMap, object;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  objectMap = ({  });

return (await asyncListFilter(objectList, async (object) => {
   matchingProperty = (getObjectProperty(object, propValue));
  objectExists = (getObjectProperty(objectMap, matchingProperty));
  objectMap[matchingProperty] = true;


 return (!objectExists);
}))
}

})
define('./functions/d527e6f438ec092dc9685e5f3d479973/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d527e6f438ec092dc9685e5f3d479973'] = function fn_d527e6f438ec092dc9685e5f3d479973(page, args) {
var url, param;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  url = String('index.html?page=') + String(page);
  var param_list = (Object.keys(args));
  for (var param_index in param_list) {
    param = param_list[param_index];
    url = String(url) + String(['&',param,'=',(getObjectProperty(args, param))].join(''));
  }

return url
}

})
define('./functions/dbc600b974764941180ab22b22b4f9b9/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dbc600b974764941180ab22b22b4f9b9'] = async function fn_dbc600b974764941180ab22b22b4f9b9(pageSize, tableSize, currentPage) {
var number;


  number = pageSize;
  if ((currentPage + 1) * pageSize > tableSize) {
    number = tableSize - currentPage * pageSize;
  }

return ([(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('paging_before')),number,(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('paging_after'))].join(''))
}

})
define('./functions/dddfbe1e57444a153964702ddd038761/code.js', () => { 

BackendlessUI.Functions.Custom['fn_dddfbe1e57444a153964702ddd038761'] = function fn_dddfbe1e57444a153964702ddd038761(code, type) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return (Object.keys(({ [`code`]: code,[`type`]: type })).reduce((template, key) => template = template.replace(':' + key, ({ [`code`]: code,[`type`]: type })[key]), (String(getObjectProperty(((function () { return BackendlessUI.getCustomSettings() })()), 'surveyURL')) + String('?code=:code&type=:type'))))
}

})
define('./functions/e10e114ea5dd45059eb63d1c004a6294/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e10e114ea5dd45059eb63d1c004a6294'] = async function fn_e10e114ea5dd45059eb63d1c004a6294(oldFileProperty, currentFileProperty, newFileProperty, dataObject) {
var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  if (getObjectProperty(dataObject, newFileProperty)) {
    returnValue2 = (await BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89']((getObjectProperty(dataObject, currentFileProperty)), (getObjectProperty(dataObject, newFileProperty))));
    delete dataObject[newFileProperty];
  }
  if (getObjectProperty(dataObject, oldFileProperty)) {
    await Backendless.Files.remove(encodePath((getObjectProperty(dataObject, oldFileProperty))));
    delete dataObject[oldFileProperty];
    returnValue2 = (getObjectProperty(dataObject, currentFileProperty));
  }

return returnValue2
}

})
define('./functions/e11eb88cdce49edd9b835522f752bf2e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_e11eb88cdce49edd9b835522f752bf2e'] = function fn_e11eb88cdce49edd9b835522f752bf2e(level) {
var level, path;

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  level = level;
  if (level == null) {
    level = 0;
  }
  if (((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).indexOf('_') !== -1) {
    path = ((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).split('_');
  } else {
    path = [((function () { return BackendlessUI.Navigator.getCurrentPageName() })())];
  }
  removeItemInList(path, 'admin', '');
  removeItemInList(path, 'landing', '');

return (path.length > level)
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/admin/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin/components/3cb5b1b942a81d5af8ae7bf5149e2cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_transactions', undefined);

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_button_transactions'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin/components/03ed4cac0ac8e0038531adf42a17208e/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_button_questions'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin/components/2e6c3cbd4223e04f8f22fa8a8434fd9b/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_button_presentations'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin/components/912cf6273fe2814ee2abe81a5664fe82/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_button_users'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin/components/3f72e16a02aaa9ea4d6bc0b62c45648e/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_button_companies'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin/components/71eafe1e90e5c24b690ab7e9d59ceff3/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_button_news'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin/components/4cabe7b68cf4604ed0776d7f894cb756/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_users', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin/components/1d6aba6388c203039ea14366a5fea355/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_companies', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin/components/c298c8c48169bf249cbd8aa47d93a69e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_news', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin/components/ef39e9cba2d276a81e460e9498d72dee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_transactions', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin/components/669fcc7275bba0863385beec03bfee34/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_presentations', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin/components/43d7d65bf10ddf5b8439ff25ef59ed5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_questions', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_companies/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadCompanies() {
  ___arguments.context.pageData['companies'] = (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, 'Company', null, null, null, ['objectId', 'name', 'orgNumber', 'invoiceMethod', 'homePage', 'count(objectId) as numUsers', 'Users[Company].objectId as userId'], null, 1, null, 'name', 'objectId', null, null, null));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'companies')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);
  try {
    ___arguments.context.pageData['isOpenModal'] = false;
    ___arguments.context.pageData['pageSize'] = 100;
    ___arguments.context.pageData['pageOffset'] = 0;
    await loadCompanies();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin_companies/components/f1f143c97e03aef1ae61b6964cab10fb/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.getComponentDataStoreByUid('3e8d125956c426a921fb289bb0d73769')

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/admin_companies/components/8b99ed8ea00f2315c8257edf3b77f837/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['selectedRow'] = null;
  (function (component) { component.focus() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('e0e47fbfb6cabd77619a456dbe134015')));

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_new'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/abeb1ea77bed7f1ff64975df2f274ebf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedEvent'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_companies/components/13faaaf25be4153a2b04edaaddede0d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin_companies/components/0f5bc183b009fb611d32bd4297252b83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_companies/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_companies/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_companies/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_orgnumber'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_companies/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_invoicemethod'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_companies/components/88795c81146ddd4765628ff3b11bf78c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'objectId')) ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_edit')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_new')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_companies/components/e0e47fbfb6cabd77619a456dbe134015/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_orgnumber'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var BRREG_Results, orgName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function textToTitleCase(str) {
 return str.replace(/\S+/g, function(txt) {
   return txt[0].toUpperCase() + txt.substring(1).toLowerCase()
 })
}


  ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['orgNumber'] = (___arguments.value.replace((new RegExp('[^\\d]', 'g')), ''));
  console.log('abc');
  if (___arguments.value.match((new RegExp('\\d{9}', 'g')))) {
    try {
      BRREG_Results = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String('https://data.brreg.no/enhetsregisteret/api/enheter/') + String(___arguments.value)))).setEncoding('utf8').send());
      orgName = textToTitleCase(getObjectProperty(BRREG_Results, 'navn'));
      orgName = ((textToTitleCase(getObjectProperty(BRREG_Results, 'navn'))).replace((new RegExp('(.*) As$', 'i')), '$1 AS'));
      ___arguments.context.pageData['BRREG'] = BRREG_Results;
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['lookupResult'] = (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_lookup_success'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['name'] = orgName;
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['homePage'] = (getObjectProperty(BRREG_Results, 'hjemmeside'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['postalAddress'] = ((getObjectProperty(BRREG_Results, 'postadresse.adresse')).join(', '));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['postalCode'] = (getObjectProperty(BRREG_Results, 'postadresse.postnummer'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['postalCity'] = (getObjectProperty(BRREG_Results, 'postadresse.poststed'));

    } catch (error) {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['lookupResult'] = (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_lookup_failed'));

    }
  }

  },
  /* handler:onChange */
  /* handler:onReadOnlyStateAssignment */
  ['onReadOnlyStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'objectId')) === 'undefined'))

  },
  /* handler:onReadOnlyStateAssignment */
  /* handler:onPlaceholderAssignment */
  async ['onPlaceholderAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c'](''))

  },
  /* handler:onPlaceholderAssignment */
  /* content */
}))

define('./pages/admin_companies/components/0bee449762fceeb2378df4e21ce88b56/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_homepage'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/1094c4b73cc73bed6e31d2c219a2fc93/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/6c985709117168f9cc5acb3b7e17df5d/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var newFile, upsertCompany, fileExtension, filePath, error, numUsers, isNew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function renameFile() {
  filePath = (getObjectProperty(upsertCompany, 'image')).slice(0, (getObjectProperty(upsertCompany, 'image')).lastIndexOf('/') + 1);
  fileExtension = subsequenceFromStartLast((getObjectProperty(upsertCompany, 'image')), (((getObjectProperty(upsertCompany, 'image')).lastIndexOf('.') + 1) - 1));
  newFile = [filePath,(getObjectProperty(upsertCompany, 'objectId')),fileExtension].join('');
  console.log('current path:');
  console.log(getObjectProperty(upsertCompany, 'image'));
  console.log('new path:');
  console.log(newFile);
  await Backendless.Files.renameFile( encodePath((getObjectProperty(upsertCompany, 'image'))), encodePath(newFile) );
  return newFile
}


  try {
    isNew = !(typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'string');
    delete ___arguments.context.dataModel['lookupResult'];
    numUsers = (getObjectProperty(___arguments.context.dataModel, 'numUsers'));
    delete ___arguments.context.dataModel['numUsers'];
    delete ___arguments.context.dataModel['userId'];
    upsertCompany = (await Backendless.Data.of('Company').deepSave( ___arguments.context.dataModel ));
    if (isNew) {
      ___arguments.context.pageData['companies'] = ([upsertCompany].concat((getObjectProperty(___arguments.context.pageData, 'companies'))));
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'companies')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', true);
    } else {
      ___arguments.context.dataModel['numUsers'] = numUsers;
    }
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('6c985709117168f9cc5acb3b7e17df5d');

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.dataModel['visible'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/admin_companies/components/da918ff38e48687259b3f6d4fa4f9374/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_edit'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/admin_companies/components/b134266ae17228d51712338358c29229/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_delete'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('caf679359136d66bc4c3d3ecbe6b0f24');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_companies/components/caf679359136d66bc4c3d3ecbe6b0f24/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_company'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_company'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('caf679359136d66bc4c3d3ecbe6b0f24');
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, deleteTx;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    deleteTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async () => {
      const _uow = deleteTx;

      _uow.bulkDelete('Event', (['relatedCompany.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')),'\''].join('')));
      _uow.delete('Company', (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')));

    })();

    await deleteTx.execute().then(function(result) {
        const error = result && result.error

        if (error) {
          throw error
        }

      return result
      });
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'companies')), (getObjectProperty(___arguments.context.pageData, 'selectedRow')), '');
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'companies')));
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger('events').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('caf679359136d66bc4c3d3ecbe6b0f24');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_companies/components/6ce404793588f5dfb4d17a531a2e111f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_numusers'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_companies/components/c5589d083c0d4ef537a7f8bdd65b09e9/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_invoicemethod'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/6922599bcfd7eb8c8387f9b70471717a/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_name'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/7e88d78f4423301e4abe7549cc56ca37/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var homePage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  homePage = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'homePage'));
  if (!(homePage.match((new RegExp('(http|https):\\/\\/', 'gi'))))) {
    homePage = String('https://') + String(homePage);
  }
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(homePage, true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_companies/components/605bb65141cb3905979d2d4652f5cd92/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_homepage'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_companies/components/658f5da1f4779242f0c6cdfcea832709/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['search'] = null;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'companies')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_companies/components/56e7c67b0bec4768adbca64df4c37433/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (___arguments.value.length > 0) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'companies')), async (item) => {


     return ((((getObjectProperty(item, 'name')).toUpperCase()).indexOf((___arguments.value.toUpperCase())) !== -1) || ((getObjectProperty(item, 'orgNumber')).indexOf(___arguments.value) !== -1));
    })));
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'companies')));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_companies/components/58cd2e8ea927e4f620cf9931342aeb2c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentPage')) < (getObjectProperty(___arguments.context.pageData, 'pageCount')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_companies/components/7499401537bce86e64985f417d85b28f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var number;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  number = (getObjectProperty(___arguments.context.pageData, 'pageSize'));
  if (((getObjectProperty(___arguments.context.pageData, 'currentPage')) + 1) * (getObjectProperty(___arguments.context.pageData, 'pageSize')) > (getObjectProperty(___arguments.context.pageData, 'tableSize'))) {
    number = (getObjectProperty(___arguments.context.pageData, 'tableSize')) - (getObjectProperty(___arguments.context.pageData, 'currentPage')) * (getObjectProperty(___arguments.context.pageData, 'pageSize'));
  }

  return ([(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('paging_before')),number,(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('paging_after'))].join(''))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/924c63ecc2a8904ee5975574c73181bc/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_email'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/aefbd8624b75e20ca81df5bf92926448/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_postaladdress'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/7363cd859a37d035dd971ec56d5bb267/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_postalcode'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_companies/components/c812c43a6e25c2a9694e64263c79f165/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_companies_postalcity'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_news/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

/**
 * Describe this function...
 */
async function loadNews() {
  (function (componentUid, dataModel) { ___arguments.context.setComponentDataStoreByUid(componentUid, dataModel) })('afed0c63fbef8d831c04c984284f5215', (await Backendless.Data.of('News').find(Backendless.DataQueryBuilder.create().setRelated(['author']).setSortBy('fromDate DESC').setPageSize(10))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('afed0c63fbef8d831c04c984284f5215', ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('afed0c63fbef8d831c04c984284f5215')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('afed0c63fbef8d831c04c984284f5215')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6cb52235bb9978f777c7204da06a4fbd', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f254a3e24c8a3ba24ccead7a2c17d30f', true);
  }
}

/**
 * Describe this function...
 */
async function loadAdmins() {
  ___arguments.context.pageData['admins'] = (await Backendless.UserService.findByRole('Administrator', false, Backendless.DataQueryBuilder.create().setProperties(['objectId', 'name']).setPageSize(10)));
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);
  try {
    await loadNews();
    await loadAdmins();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger('landing').error((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin_news/components/7b1eeacb05dfcdd29732fb9babd5f326/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_new'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = null;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('ceecb7e2de0a54a1eb6f6a01c91f6abe');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_news/components/99eb4488e75876ee1e63763afc81693c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_author'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_news/components/e50df1a577a18e85f5d8d5e84d91457d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_image'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_news/components/382c49639e34ad086f4bce789687b6f4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_fromdate'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_news/components/bb9647b2a6150bff185f78c4fb5aafdf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_news/components/2fd98dc239c5e4f56bdf6f315cdc9d9d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('1e86e52b5e3793061981df5228b20d3b');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_edit'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/admin_news/components/befa74063f114eba58b1c642bfc2085e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_news/components/0e093fb65c127f3508a7eb9a90d64a6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_news/components/806e7664ad8939262a55dc43dab8d8a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin_news/components/2f42ff28ad2823a53e2455dcbfdb233f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_preamble'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_news/components/cb8b65d890fdb415de7b9de3f8766249/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_title'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_news/components/48988d3e3ae94ded4d771a73961f4aa1/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_fromdate'))

  },
  /* handler:onLabelAssignment */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'fromDate')) === 'undefined') {
    returnValue2 = ((new Date()).valueOf());
    ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['fromDate'] = returnValue2;
  } else {
    returnValue2 = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'fromDate'));
  }

  return returnValue2

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['fromDate'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_news/components/f2a54e619b6e26b47dd9997a10592c75/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_todate'))

  },
  /* handler:onLabelAssignment */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'fromDate')) === 'undefined') ? '' : (new Date((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'fromDate')))).getTime() + ((1 * 86400) * 1000))))

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/admin_news/components/13c6f4cb43b959dff47b6e06f088a28b/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_url'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_news/components/f86da5f3b4257df80db36a98bc78a38d/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_author'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['author'] = (await BackendlessUI.Functions.Custom['fn_5b7b17c2e6f9eaa9839412a702eaa2ca'](((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).options })('f86da5f3b4257df80db36a98bc78a38d')), 'objectId', ___arguments.value));
  console.log(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'));

  },
  /* handler:onChange */
  /* handler:onOptionsAssignment */
  ['onOptionsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'admins'))

  },
  /* handler:onOptionsAssignment */
  /* content */
}))

define('./pages/admin_news/components/e4c859411662115a1ecaf6e22cbc59ea/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_news/components/ceecb7e2de0a54a1eb6f6a01c91f6abe/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, upsertNews, isNew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    isNew = !(typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'string');
    ___arguments.context.dataModel['image'] = (await BackendlessUI.Functions.Custom['fn_e10e114ea5dd45059eb63d1c004a6294']('oldFile_image', 'image', 'newFile_image', ___arguments.context.dataModel));
    upsertNews = (await Backendless.Data.of('News').deepSave( ___arguments.context.dataModel ));
    if (isNew) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('afed0c63fbef8d831c04c984284f5215', ([upsertNews].concat(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('afed0c63fbef8d831c04c984284f5215')))));
    }
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('ceecb7e2de0a54a1eb6f6a01c91f6abe');

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.dataModel['author'] = ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'name': (getObjectProperty(___arguments.context.pageData, 'currentUser.name')) });
  ___arguments.context.dataModel['fromDate'] = (new Date());
  ___arguments.context.dataModel['text'] = '';

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/admin_news/components/3cd25af602846d424f7b3d93161423e2/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_news'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_news'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('3cd25af602846d424f7b3d93161423e2');
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    await Backendless.Data.of('News').remove( (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) );
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('afed0c63fbef8d831c04c984284f5215')), (getObjectProperty(___arguments.context.pageData, 'selectedRow')), '');
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('3cd25af602846d424f7b3d93161423e2');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_news/components/abc4781be9edee91b0495206e5cd879d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('3cd25af602846d424f7b3d93161423e2');
  ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('1e86e52b5e3793061981df5228b20d3b');

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_delete'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/admin_news/components/e70f24da4cbf69059b9d9f614d86ea15/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e86e52b5e3793061981df5228b20d3b'), 'fromDate')), true))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_news/components/bb14da8d91698d34079d8e9f164769ad/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) === 'string') ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_edit')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_new')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_news/components/f65c7f7267efa7b88506c8fc0c91f7aa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'newFile_image')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'newFile_image'))));
      delete ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['newFile_image'];
    } else {
      ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['oldFile_image'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'image'));
      ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['image'] = null;
    }

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_news/components/d014796a291830ed125fe13dd8c9fbcb/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'newFile_image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'newFile_image')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'image')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/admin_news/components/ddaa74d8b3c9bf2893e7ec92be09c8e9/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'newFile_image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'newFile_image')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'image')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_news/components/cbb171fa4db1007c86f8281d69b2752d/bundle.js', [], () => ({
  /* content */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'newFile_image')) {
      ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['newFile_image'] = (await BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe'), 'image')), ___arguments.uploadedFiles));
    } else {
      ___arguments.context.getComponentDataStoreByUid('ceecb7e2de0a54a1eb6f6a01c91f6abe')['newFile_image'] = ___arguments.uploadedFiles;
    }
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cbb171fa4db1007c86f8281d69b2752d');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
    var error;


  await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('d89362833564bca91097599b783da311', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('cbb171fa4db1007c86f8281d69b2752d');
  Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to upload file:\n') + String(error)));

  },
  /* handler:onUploadFail */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/images/news/'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_28b9f8ff56eaf2b3acc180ca591e924e'](___arguments.file))

  },
  /* handler:onFileNameAssignment */
  /* handler:onButtonLabelAssignment */
  async ['onButtonLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image'))

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onUploadSuccessMsgAssignment */
  async ['onUploadSuccessMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_success'))

  },
  /* handler:onUploadSuccessMsgAssignment */
  /* handler:onUploadFailMsgAssignment */
  async ['onUploadFailMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_error'))

  },
  /* handler:onUploadFailMsgAssignment */
  /* handler:onUploadProgressMsgAssignment */
  async ['onUploadProgressMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_ongoing'))

  },
  /* handler:onUploadProgressMsgAssignment */
  /* content */
}))

define('./pages/admin_news/components/62ac2e5dfd4e7898b79ce2d83db99842/bundle.js', [], () => ({
  /* content */
  /* handler:placeholderLogic */
  async ['placeholderLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_news_description'))

  },
  /* handler:placeholderLogic */
  /* content */
}))

define('./pages/admin_news/components/d23ece35b6b2cc21966c329fd529432d/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1e86e52b5e3793061981df5228b20d3b'), 'image')) ? 'check circle' : 'radio button unchecked')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

/**
 * Describe this function...
 */
async function loadPresentations() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await Backendless.Data.of('Presentation').find(Backendless.DataQueryBuilder.create().setRelated(['relatedCertification']).setSortBy('name').setPageSize(10))));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}

/**
 * Describe this function...
 */
async function loadTools() {
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('704811be2bc673760c6ce8db236c847f', (await Backendless.Data.of('Certification').find(Backendless.DataQueryBuilder.create().setProperties(['objectId', 'name']).setSortBy('name').setPageSize(50))));
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);
  try {
    ___arguments.context.pageData['isOpenModal'] = false;
    await loadPresentations();
    await loadTools();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin_presentations/components/8b99ed8ea00f2315c8257edf3b77f837/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_new'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/abeb1ea77bed7f1ff64975df2f274ebf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_presentations/components/13faaaf25be4153a2b04edaaddede0d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/0f5bc183b009fb611d32bd4297252b83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_presentations/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_relatedcertification'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_keynote'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/88795c81146ddd4765628ff3b11bf78c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'objectId')) ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_edit')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_new')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/e0e47fbfb6cabd77619a456dbe134015/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_name'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/c2a195354187398551fed9dcab518177/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_description'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/704811be2bc673760c6ce8db236c847f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_category'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['relatedCertification'] = ((await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).options })('704811be2bc673760c6ce8db236c847f')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == ___arguments.value);
  }))[0]);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_presentations/components/1094c4b73cc73bed6e31d2c219a2fc93/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/e26c035de9466413ccac08d77461ba27/bundle.js', [], () => ({
  /* content */
  /* handler:onButtonLabelAssignment */
  async ['onButtonLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image'))

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/images/presentations/'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_image'] = (await BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image')), ___arguments.uploadedFiles));
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_image'] = ___arguments.uploadedFiles;
    }
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('e26c035de9466413ccac08d77461ba27');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_28b9f8ff56eaf2b3acc180ca591e924e'](___arguments.file))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
    var error;


  await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('e26c035de9466413ccac08d77461ba27');
  Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to upload file:\n') + String(error)));

  },
  /* handler:onUploadFail */
  /* handler:onUploadSuccessMsgAssignment */
  async ['onUploadSuccessMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_success'))

  },
  /* handler:onUploadSuccessMsgAssignment */
  /* handler:onUploadFailMsgAssignment */
  async ['onUploadFailMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_error'))

  },
  /* handler:onUploadFailMsgAssignment */
  /* handler:onUploadProgressMsgAssignment */
  async ['onUploadProgressMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_ongoing'))

  },
  /* handler:onUploadProgressMsgAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/3244f5a194b70a4f861b759d4e3f37b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_pdf'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/6c985709117168f9cc5acb3b7e17df5d/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var changelog, error, isNew, item, upsertPresentation;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    isNew = !(typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'string');
    ___arguments.context.dataModel['image'] = (await BackendlessUI.Functions.Custom['fn_e10e114ea5dd45059eb63d1c004a6294']('oldFile_image', 'image', 'newFile_image', ___arguments.context.dataModel));
    ___arguments.context.dataModel['keynote'] = (await BackendlessUI.Functions.Custom['fn_e10e114ea5dd45059eb63d1c004a6294']('oldFile_keynote', 'keynote', 'newFile_keynote', ___arguments.context.dataModel));
    ___arguments.context.dataModel['powerpoint'] = (await BackendlessUI.Functions.Custom['fn_e10e114ea5dd45059eb63d1c004a6294']('oldFile_powerpoint', 'powerpoint', 'newFile_powerpoint', ___arguments.context.dataModel));
    ___arguments.context.dataModel['pdf'] = (await BackendlessUI.Functions.Custom['fn_e10e114ea5dd45059eb63d1c004a6294']('oldFile_pdf', 'pdf', 'newFile_pdf', ___arguments.context.dataModel));
    changelog = (getObjectProperty(___arguments.context.dataModel, 'changelog')).trim();
    if (changelog.match((new RegExp('^(?![0-9]).*', 'g')))) {
      changelog = [(await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((new Date()), false)),':\n',changelog].join('');
    }
    ___arguments.context.dataModel['changelog'] = changelog;
    upsertPresentation = (await Backendless.Data.of('Presentation').deepSave( ___arguments.context.dataModel ));
    Object.assign((getObjectProperty(___arguments.context.pageData, 'selectedRow')), upsertPresentation);
    if (isNew) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', ([upsertPresentation].concat(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')))));
    }
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('6c985709117168f9cc5acb3b7e17df5d');

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  async ['onReset'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function cleanupFiles() {
  if (getObjectProperty(___arguments.prevData, 'newFile_keynote')) {
    await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.prevData, 'newFile_keynote'))));
  }
  if (getObjectProperty(___arguments.prevData, 'newFile_powerpoint')) {
    await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.prevData, 'newFile_powerpoint'))));
  }
  if (getObjectProperty(___arguments.prevData, 'newFile_pdf')) {
    await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.prevData, 'newFile_pdf'))));
  }
}


  ___arguments.context.pageData['isOpenModal'] = false;
  await cleanupFiles();

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/admin_presentations/components/da918ff38e48687259b3f6d4fa4f9374/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_edit'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/b134266ae17228d51712338358c29229/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_delete'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('caf679359136d66bc4c3d3ecbe6b0f24');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_presentations/components/caf679359136d66bc4c3d3ecbe6b0f24/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_presentation'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_presentation'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('caf679359136d66bc4c3d3ecbe6b0f24');
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'selectedRow.image')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'selectedRow.image'))));
    }
    if (getObjectProperty(___arguments.context.pageData, 'selectedRow.keynote')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'selectedRow.keynote'))));
    }
    if (getObjectProperty(___arguments.context.pageData, 'selectedRow.powerpoint')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'selectedRow.powerpoint'))));
    }
    if (getObjectProperty(___arguments.context.pageData, 'selectedRow.pdf')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'selectedRow.pdf'))));
    }
    await Backendless.Data.of('Presentation').remove( (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) );
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')), (getObjectProperty(___arguments.context.pageData, 'selectedRow')), '');
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger('events').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('caf679359136d66bc4c3d3ecbe6b0f24');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_presentations/components/913df58c5a65081a942772133e2ca18d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image'))));
      delete ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_image'];
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['oldFile_image'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['image'] = null;
    }

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, 'delete_error', 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_presentations/components/447a3a3b7c7767962f1c7b867fed34dd/bundle.js', [], () => ({
  /* content */
  /* handler:onButtonLabelAssignment */
  async ['onButtonLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_upload_keynote'))

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/files/presentations/'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_keynote')) {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_keynote'] = (await BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'keynote')), ___arguments.uploadedFiles));
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_keynote'] = ___arguments.uploadedFiles;
    }
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('447a3a3b7c7767962f1c7b867fed34dd');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
    var error;


  await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_file_error')), 'error');
  Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to upload file:\n') + String(error)));

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_28b9f8ff56eaf2b3acc180ca591e924e'](___arguments.file))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadProgressMsgAssignment */
  async ['onUploadProgressMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('uploading'))

  },
  /* handler:onUploadProgressMsgAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/36e78c53d5b9aa6bdc67566e7e63788e/bundle.js', [], () => ({
  /* content */
  /* handler:onButtonLabelAssignment */
  async ['onButtonLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_upload_powerpoint'))

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/files/presentations/'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_powerpoint')) {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_powerpoint'] = (await BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'powerpoint')), ___arguments.uploadedFiles));
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_powerpoint'] = ___arguments.uploadedFiles;
    }
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('36e78c53d5b9aa6bdc67566e7e63788e');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
    var error;


  await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_file_error')), 'error');
  Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to upload file:\n') + String(error)));

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_28b9f8ff56eaf2b3acc180ca591e924e'](___arguments.file))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadProgressMsgAssignment */
  async ['onUploadProgressMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('uploading'))

  },
  /* handler:onUploadProgressMsgAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/a3fa00270a7d1543376724fa1605b95e/bundle.js', [], () => ({
  /* content */
  /* handler:onButtonLabelAssignment */
  async ['onButtonLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_upload_pdf'))

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/files/presentations/'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_pdf')) {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_pdf'] = (await BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'pdf')), ___arguments.uploadedFiles));
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_pdf'] = ___arguments.uploadedFiles;
    }
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('a3fa00270a7d1543376724fa1605b95e');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
    var error;


  await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_file_error')), 'error');
  Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to upload file:\n') + String(error)));

  },
  /* handler:onUploadFail */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_28b9f8ff56eaf2b3acc180ca591e924e'](___arguments.file))

  },
  /* handler:onFileNameAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/2a9dace51274eb972d2c89e31b57cde2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_keynote')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_keynote')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'keynote'))), true, undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_keynote')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'keynote')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/51f7698c928b8c8add253885db1988e1/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_keynote')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'keynote')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_keynote')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_keynote'))));
      delete ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_keynote'];
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['oldFile_keynote'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'keynote'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['keynote'] = null;
    }

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_presentations/components/07669d7a9cf3e82ab258f42ab1462dba/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_pdf')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'pdf')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_pdf')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_pdf'))));
      delete ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_pdf'];
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['oldFile_pdf'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'pdf'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['pdf'] = null;
    }

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_presentations/components/c123d4759639b1731e2d3c982089da92/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_powerpoint')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'powerpoint')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_powerpoint')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_powerpoint'))));
      delete ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_powerpoint'];
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['oldFile_powerpoint'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'powerpoint'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['powerpoint'] = null;
    }

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_presentations/components/a15c0d9ccee9ff67002b37cf7c26105d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_powerpoint')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_powerpoint')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'powerpoint'))), true, undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_powerpoint')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'powerpoint')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/1a9f9f1d525237f755b2d817c69419f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_pdf')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_pdf')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'pdf'))), true, undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_pdf')) || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'pdf')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/6ce404793588f5dfb4d17a531a2e111f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_powerpoint'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/d47bebaf3f428aeefd53f98f897f2e83/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_image'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/a4a4590c171eb701b5b60f59053a636f/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image')))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/630b45992f19c5627d3c916b1766ceb4/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_presentations/components/e44d694722ab347bb0ba17d85978b333/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_changelog'))

  },
  /* handler:onLabelAssignment */
  /* handler:onPlaceholderAssignment */
  async ['onPlaceholderAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_presentations_changelog_placeholder'))

  },
  /* handler:onPlaceholderAssignment */
  /* content */
}))

define('./pages/admin_questions/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

/**
 * Describe this function...
 */
async function loadQuestions() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await Backendless.Data.of('RelCo_Question').find(Backendless.DataQueryBuilder.create().setRelated(['Rel_Topic']).setRelationsDepth(1).setSortBy('id').setPageSize(100))));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);
  try {
    ___arguments.context.pageData['isOpenModal'] = false;
    await loadQuestions();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin_questions/components/abeb1ea77bed7f1ff64975df2f274ebf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedEvent'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_questions/components/13faaaf25be4153a2b04edaaddede0d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin_questions/components/0f5bc183b009fb611d32bd4297252b83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_questions/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_topic'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_own_single'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_own_manager'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/88795c81146ddd4765628ff3b11bf78c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_edit'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/1094c4b73cc73bed6e31d2c219a2fc93/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_questions/components/6c985709117168f9cc5acb3b7e17df5d/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var newFile, upsertQuestion, fileExtension, filePath, error, numUsers, isNew;


  try {
    upsertQuestion = (await Backendless.Data.of('RelCo_Question').deepSave( ___arguments.context.dataModel ));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('6c985709117168f9cc5acb3b7e17df5d');

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/admin_questions/components/caf679359136d66bc4c3d3ecbe6b0f24/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_company'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_company'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'isOpenDeleteModal'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClose */
  ['onClose'](___arguments) {
      ___arguments.context.pageData['isOpenDeleteModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var deleteTx, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    deleteTx = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async function() {
      const _uow = deleteTx;

      _uow.bulkDelete('Event', (['relatedCompany.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')),'\''].join('')));
      _uow.delete('Company', (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')));

    })();

    await deleteTx.execute().then(function(result) {
        const error = result && result.error

        if (error) {
          throw error
        }

      return result
      });
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')), (getObjectProperty(___arguments.context.pageData, 'selectedRow')), '');
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger('events').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['isOpenDeleteModal'] = false;

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_questions/components/6ce404793588f5dfb4d17a531a2e111f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_manager'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/6922599bcfd7eb8c8387f9b70471717a/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_topic'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_questions/components/605bb65141cb3905979d2d4652f5cd92/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_colleague'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/e25ef1dcffe5f84627adb3c62ce23016/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_employee'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_questions/components/f1f143c97e03aef1ae61b6964cab10fb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.dataModel;
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_questions/components/9b16691496a2615535fc3dd541bf27ce/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_own_single'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_questions/components/c5d7a7f572b45963f1af1cbd3043dfe1/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_own_manager'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_questions/components/6d9e1e04d79971bbc20af0b77b3cdd6d/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_colleague'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_questions/components/87eb768bebd506989af9a1333f687692/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_manager'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_questions/components/a06c348bfd51103567959ad3e0cddb79/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_employee'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_questions/components/507169aae105b4ce9af299752449abd3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_questions_info'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

/**
 * Describe this function...
 */
async function loadTransactions() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await Backendless.Data.of('User_Transaction').find(Backendless.DataQueryBuilder.create().setWhereClause('status IN (\'Pending\',\'Invoiced\',\'Paid\',\'Correction\')').setProperties(['Users[Related_Transactions].name as userName', 'Users[Related_Transactions].Company.name as companyName', '*']).setSortBy('created DESC').setPageSize(50))));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);
  try {
    ___arguments.context.pageData['isOpenModal'] = false;
    ___arguments.context.pageData['selectedRows'] = [];
    await loadTransactions();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin_transactions/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_transactions_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_transactions_company'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_transactions_user'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_transactions_status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/6ce404793588f5dfb4d17a531a2e111f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_transactions_date'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/605bb65141cb3905979d2d4652f5cd92/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_transactions_number_tokens'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/eee606e22e232a81392120488bd5ac46/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var whereClause;


  if (___arguments.value == 'ALL') {
    whereClause = 'status IN (\'Pending\',\'Invoiced\',\'Paid\')';
  } else {
    whereClause = ['status=\'',___arguments.value,'\''].join('');
  }
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await Backendless.Data.of('User_Transaction').find(Backendless.DataQueryBuilder.create().setWhereClause(whereClause).setProperties(['Users[Related_Transactions].name as userName', 'Users[Related_Transactions].Company.name as companyName', '*']).setSortBy('created DESC').setPageSize(50))));

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_transactions/components/235f740af0895377f178f10d6095fb5e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'created')), true))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_transactions/components/36f5b2662ee7c46bdc7aade80b56d3e9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_transactions_invoicedate'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadUsers() {
  ___arguments.context.pageData['users'] = (await Backendless.Request.get(`${Backendless.appPath}/services/userAdmin/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send());
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'users')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}

/**
 * Describe this function...
 */
async function loadCompany() {
  ___arguments.context.pageData['Companies'] = (await Backendless.Data.of('Company').find(Backendless.DataQueryBuilder.create().setProperties(['objectId', 'name']).setSortBy('name').setPageSize(100)));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('02d40a688d723e7142a26938feca657f', (getObjectProperty(___arguments.context.pageData, 'Companies')));
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);
  try {
    ___arguments.context.pageData['isOpenModal'] = false;
    await loadUsers();
    await loadCompany();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin_users/components/8b99ed8ea00f2315c8257edf3b77f837/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['selectedUser'] = ({ 'name': undefined,'email': undefined,'companyId': undefined,'companyName': undefined,'roles': [] });

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_new'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users/components/abeb1ea77bed7f1ff64975df2f274ebf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedUser'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users/components/13faaaf25be4153a2b04edaaddede0d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin_users/components/0f5bc183b009fb611d32bd4297252b83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedUser'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_email'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_roles'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/88795c81146ddd4765628ff3b11bf78c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'selectedUser.objectId')) === 'undefined') ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_new')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_edit')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/e0e47fbfb6cabd77619a456dbe134015/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_firstname'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users/components/1094c4b73cc73bed6e31d2c219a2fc93/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnValue2 = true;
  if (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'roles')) === 'undefined') && !!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'roles')).length) {
    returnValue2 = false;
  }

  return returnValue2

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/admin_users/components/6c985709117168f9cc5acb3b7e17df5d/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, upsertUser, isNew, selectedCompany;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    isNew = (typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'undefined');
    selectedCompany = (await BackendlessUI.Functions.Custom['fn_5b7b17c2e6f9eaa9839412a702eaa2ca']((getObjectProperty(___arguments.context.pageData, 'Companies')), 'objectId', ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('02d40a688d723e7142a26938feca657f'))));
    ___arguments.context.dataModel['name'] = ([(getObjectProperty(___arguments.context.dataModel, 'firstname')),' ',(getObjectProperty(___arguments.context.dataModel, 'lastname'))].join(''));
    upsertUser = (await Backendless.Request.put(`${Backendless.appPath}/services/userAdmin/upsert`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'lastname': (getObjectProperty(___arguments.context.dataModel, 'lastname')),'email': (getObjectProperty(___arguments.context.dataModel, 'email')),'roles': (getObjectProperty(___arguments.context.dataModel, 'roles')),'company': selectedCompany,'objectId': (getObjectProperty(___arguments.context.dataModel, 'objectId')),'firstname': (getObjectProperty(___arguments.context.dataModel, 'firstname')) }));
    if (isNew) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', ([upsertUser].concat(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')))));
    }
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedUser'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('6c985709117168f9cc5acb3b7e17df5d');

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.dataModel['visible'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/admin_users/components/aadfccfdb0b737148641ab4c4c1bd41e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'lastLogin')), true))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/a264f26ca00a030d1b24fa91ef93c9de/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_role_instructor'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'roles')).includes('Instructor'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_users/components/80d48911c2dcf9ed881d15ba8c76e32d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'roles')).includes('Administrator'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_role_administrator'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users/components/3f3838265c6a5c54e2e9f3593c8daffc/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_role_instructor'))

  },
  /* handler:onLabelAssignment */
  /* handler:onCheckedStateAssignment */
  async ['onCheckedStateAssignment'](___arguments) {
    var return2, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_cc83f03b6e47613d43acc4543652847f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'roles')), 'Instructor'))

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var roles, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  roles = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'roles'));
  if (___arguments.value) {
    addItemToList(roles, 'Instructor');
  } else {
    removeItemInList(roles, 'Instructor', '');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_users/components/572921d14662f11ca6dfa04e62014ac3/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_role_administrator'))

  },
  /* handler:onLabelAssignment */
  /* handler:onCheckedStateAssignment */
  async ['onCheckedStateAssignment'](___arguments) {
    var roles;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_cc83f03b6e47613d43acc4543652847f']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'roles')), 'Administrator'))

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var roles;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  roles = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'roles'));
  if (___arguments.value) {
    addItemToList(roles, 'Administrator');
  } else {
    removeItemInList(roles, 'Administrator', '');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_users/components/eb012e7bdb413255c6fdf9ea050cf47f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedUser'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'userStatus')) != 'DISABLED' && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'objectId')) != (getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_edit'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/admin_users/components/ebc9c432a3f57ff47a11bacf56e6a03b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin_users_detail', ({ 'selectedUser': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'objectId')) }));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users/components/f196f78150b46382c52f194b13ce1866/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_company'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/02d40a688d723e7142a26938feca657f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_company'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['Company'] = ((await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).options })('02d40a688d723e7142a26938feca657f')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == ___arguments.value);
  }))[0]);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_users/components/34a1d98109a5721725b4ca18b9dd1986/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'userStatus')) == 'EMAIL_CONFIRMATION_PENDING' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('email_sending')), 'info');
    await Backendless.UserService.resendEmailConfirmation((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'email')));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('email_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('email_error')), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to send email:\n') + String(error)));

  }

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_resendinvite'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/admin_users/components/a7579606fce7e97805d64d75cf566306/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'userStatus')) != 'DISABLED' && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'objectId')) != (getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_delete'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedUser'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('cdf30ae9b2a88d39e029cb71ca092bb9');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users/components/39e4e997ff7b52061a3e7e973daea702/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'userStatus')) == 'DISABLED' ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_restore'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, updatedUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    updatedUser = (await Backendless.Request.put(`${Backendless.appPath}/services/userAdmin/restore`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send(JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'objectId')))));
    Object.assign(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), updatedUser);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to save/update record:\n') + String(error)));

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users/components/cdf30ae9b2a88d39e029cb71ca092bb9/bundle.js', [], () => ({
  /* content */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_user'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_user'))

  },
  /* handler:contentLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('cdf30ae9b2a88d39e029cb71ca092bb9');
  ___arguments.context.pageData['selectedUser'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, updatedUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    updatedUser = (await Backendless.Request.delete(`${Backendless.appPath}/services/userAdmin/delete`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'selectedUser.objectId')))));
    if (updatedUser == null) {
      removeItemInList(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')), (getObjectProperty(___arguments.context.pageData, 'selectedUser')), '');
    } else {
      Object.assign((getObjectProperty(___arguments.context.pageData, 'selectedUser')), updatedUser);
    }
    ___arguments.context.pageData['selectedUser'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('cdf30ae9b2a88d39e029cb71ca092bb9');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_users/components/4c54c96d5ed6c93550c7eace5e639b64/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_lastname'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users/components/d2c4460234e40712bab6e7f95d920783/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['search'] = null;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'users')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users/components/9707061e6dda9a7f1e6a20465486f415/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (___arguments.value.length > 0) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'users')), async (item) => {


     return ((((getObjectProperty(item, 'firstname')).toUpperCase()).indexOf((___arguments.value.toUpperCase())) !== -1) || (((getObjectProperty(item, 'lastname')).toUpperCase()).indexOf((___arguments.value.toUpperCase())) !== -1) || (((getObjectProperty(item, 'email')).toUpperCase()).indexOf((___arguments.value.toUpperCase())) !== -1) || (((getObjectProperty(item, 'Company.name')).toUpperCase()).indexOf((___arguments.value.toUpperCase())) !== -1));
    })));
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'users')));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_users/components/ccba724fd68d80879eb1df73ab9b53df/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'created')), true))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/c2d9dc97bbe2263f9954f2ef4fcbaa8b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_created'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users/components/6ce404793588f5dfb4d17a531a2e111f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_lastlogin'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadDetails() {
  ___arguments.context.pageData['userDetails'] = (await Backendless.Request.get(`${Backendless.appPath}/services/userAdmin/users/${(getObjectProperty(___arguments.context.pageData, 'selectedUser'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send());
  ___arguments.context.pageData['certifications'] = (await Backendless.Data.of('Certification').find(Backendless.DataQueryBuilder.create().setProperties(['objectId', 'name']).setSortBy('name').setPageSize(100)));
}

/**
 * Describe this function...
 */
async function loadCerts() {
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('b7164f92ea8d4455411d9b32a469d81f', (getObjectProperty(___arguments.context.pageData, 'certifications')));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'userDetails.Related_Certifications')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}

/**
 * Describe this function...
 */
async function loadTrans() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('705dbeffe35610a261ff5ebcbdaf0334', (getObjectProperty(___arguments.context.pageData, 'userDetails.Related_Transactions')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('705dbeffe35610a261ff5ebcbdaf0334')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff9412e5adc30d4177baaf7829c414f5', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('705dbeffe35610a261ff5ebcbdaf0334', false);
  }
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Administrator', ___arguments.context.appData);
  try {
    if (!(typeof (getObjectProperty(___arguments.context.pageData, 'selectedUser')) === 'string')) {
      throw (new Error('Missing input'))
    }
    await loadDetails();
    await loadCerts();
    await loadTrans();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/admin_users_detail/components/eaa14cb931ff1a6c85682bbdafc90d36/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'certifiedFromDate')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/e8efba83e982561d157299e802f6cc0a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'certifiedToDate')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/5afe68b8aaaf07a7f123b5330ee7d06c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_subheader_userinfo'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/edf155f76edd92b56cbc4ae9a80daab9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5419acce7db3ee3daecc339a16e78129'), 'created')))).toLocaleString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/e248dfa3bdd8b153651b43fd4f9b5a81/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']((String('status_') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5419acce7db3ee3daecc339a16e78129'), 'status')).toLowerCase()))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/a49e49362c74d1065538fd6778c3616d/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var profileImage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  profileImage = (getObjectProperty(___arguments.context.pageData, 'userDetails.profileImage'));
  if (!(typeof profileImage === 'string')) {
    profileImage = String(Backendless.appPath) + String('/files/images/profiles/default.jpg');
  }

  return profileImage

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/416ae747fef648b97678efcd4c5b76fc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_user_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/ee3645e2fe3c35cc0d2aad59771ee2c9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.pageData, 'userDetails.lastLogin')), true))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/511806f61a242ce2d30280f18dc629d6/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_new'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['selectedRow'] = ({ 'objectId': undefined,'relatedCertification': ({ 'objectId': undefined }),'certifiedFromDate': undefined,'certifiedToDate': undefined,'note': undefined });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users_detail/components/c75873fccd6fbd67d4a90bf60d97b30e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users_detail/components/1d6135e60fc5b971156b10c4316f338a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'isOpenModal')) ? ['modal', 'open'] : ['modal'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/54b13e3c0a5b5613b0c2456e823e7ba8/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_fromdate'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/584cff0ff45e02cd17df69820bc81677/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_todate'))

  },
  /* handler:onLabelAssignment */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6dbd92585c240029e3ea164fe5dc6805'), 'certifiedFromDate')) === 'undefined') ? '' : (new Date((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6dbd92585c240029e3ea164fe5dc6805'), 'certifiedFromDate')))).getTime() + ((1 * 86400) * 1000))))

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/5f915f05c9f82ed17a54927f78891a53/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) === 'string') ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_edit')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_new')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/b7164f92ea8d4455411d9b32a469d81f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_name'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('6dbd92585c240029e3ea164fe5dc6805')['relatedCertification'] = ((await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).options })('b7164f92ea8d4455411d9b32a469d81f')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == ___arguments.value);
  }))[0]);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/admin_users_detail/components/6e84e40fbc3bbf84bef920469d45a884/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_user_lastlogin'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/5a3dee98f65b0bbecebbcc64dfc75088/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_user_email'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/5b2bcac178231f696d52bc2967baaabc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_company_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/8439598d9e319ae1698170c7b104c9ba/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_company_orgnumber'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/ef63fab62445555ca7527c6cbfcb3316/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_subheader_companyinfo'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/6dbd92585c240029e3ea164fe5dc6805/bundle.js', [], () => ({
  /* content */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onReset */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, upsertEvent, isNew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    isNew = !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) === 'string');
    if (isNew) {
      upsertEvent = (await Backendless.Data.of('Users').deepSave( ({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'userDetails.objectId')),'Related_Certifications': [___arguments.context.dataModel] }) ));
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', ((getObjectProperty(upsertEvent, 'Related_Certifications')).concat(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')))));
    } else {
      upsertEvent = (await Backendless.Data.of('User_Certification').deepSave( ___arguments.context.dataModel ));
    }
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('6dbd92585c240029e3ea164fe5dc6805');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_users_detail/components/7971c031de038c9f043e5458fa6a75dc/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_certification'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_certification'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('7971c031de038c9f043e5458fa6a75dc');
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    await Backendless.Data.of('User_Certification').remove( (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) );
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')), (getObjectProperty(___arguments.context.pageData, 'selectedRow')), '');
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('7971c031de038c9f043e5458fa6a75dc');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_users_detail/components/097760d9f1a9b713da88b8e7d50ef32e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_note'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/f70c19fad4dac3c93d1dae9f345cc2b4/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_edit'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users_detail/components/c1f4f9cd92bc456e45318d9ff8560049/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_certifications_delete'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('7971c031de038c9f043e5458fa6a75dc');
  ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users_detail/components/37f470d56cfc297bf432ec1e54ecf62a/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('lang_admin_users_detail_certifications_note'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/060a5a2777de507625128daa9c1fef67/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/b625d54766375f5c8adaab7fae12ac9e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5419acce7db3ee3daecc339a16e78129'), 'status')) == 'Pending')

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('5419acce7db3ee3daecc339a16e78129');
  ___arguments.context.pageData['isOpenResetModal'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users_detail/components/96b60acb18b2ebe6084241c7742afc0e/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_reset_transaction_title'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_reset_transaction_text'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_reset_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_reset_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'isOpenResetModal'))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClose */
  ['onClose'](___arguments) {
      ___arguments.context.pageData['isOpenResetModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, transaction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    transaction = (getObjectProperty(___arguments.context.pageData, 'selectedRow'));
    transaction['unitPrice'] = 0;
    transaction['note'] = ([(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('reset_transaction_start')),(getObjectProperty(___arguments.context.pageData, 'currentUser.name')),(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('reset_transaction_date')),(await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((new Date()), true))].join(''));
    await Backendless.Data.of('User_Transaction').deepSave( transaction );
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to reset transaction record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['isOpenResetModal'] = false;

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_users_detail/components/6ea1412883e159e9a90552c4661b9075/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_user_numtokens'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/31c5d5a3d3531af9f02b65a8498bf37d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var returnVal, transactions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.pageData, 'userDetails.Related_Transactions')) === 'undefined') {
    returnVal = false;
  } else {
    transactions = (getObjectProperty(___arguments.context.pageData, 'userDetails.Related_Transactions')).length;
    returnVal = transactions / 50 >= 1;
    if (transactions % 50 > 0) {
      returnVal = false;
    }
  }

  return returnVal

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/12d213f24465cf9f772cc962462360a3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var moreTransactions, transactions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  transactions = (getObjectProperty(___arguments.context.pageData, 'userDetails.Related_Transactions'));
  moreTransactions = (await Backendless.Request.get(`${Backendless.appPath}/services/userAdmin/users/${(getObjectProperty(___arguments.context.pageData, 'userDetails.objectId'))}/transactions`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).query({ 'offset': (transactions.length) }).send());
  ___arguments.context.pageData['userDetails.Related_Transactions'] = (transactions.concat(moreTransactions));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('705dbeffe35610a261ff5ebcbdaf0334', (getObjectProperty(___arguments.context.pageData, 'userDetails.Related_Transactions')));

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_load_more'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/b7e431f2f425a973b47441870026dc69/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_transactions_new'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenTransactionModal'] = true;
  ___arguments.context.pageData['selectedRow'] = ({ [`objectId`]: undefined,[`tokens`]: undefined,[`unitPrice`]: undefined,[`note`]: undefined });

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users_detail/components/c8029dc93545b6c6ed6237738ff3ec1b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'isOpenTransactionModal')) ? ['modal', 'open'] : ['modal'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/13611c24b333cdb13dd6707c63af9eb5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenTransactionModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/admin_users_detail/components/306cb3a6230b02baeedb96430b3e5591/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_transactions_new'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/f94bc6aae22fae218bb5365bd2d8f7b2/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/7f35cad3ab6f21077b7c66c9c76422ef/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_transactions_tokens'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/568777238e1c7c938798e06a8d1f89ba/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_transactions_unitprice'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/7ac1b15ec0f57f93899264749adf17e3/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_transactions_note'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/eb2249de7a2f14690fa849e22b412bfc/bundle.js', [], () => ({
  /* content */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.pageData['isOpenTransactionModal'] = false;

  },
  /* handler:onReset */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, upsertEvent;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    upsertEvent = (await Backendless.Request.post(`${Backendless.appPath}/services/userAdmin/users/transactions`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'userId': (getObjectProperty(___arguments.context.pageData, 'userDetails.objectId')),'trans': ___arguments.context.dataModel }));
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('705dbeffe35610a261ff5ebcbdaf0334', (upsertEvent.concat(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('705dbeffe35610a261ff5ebcbdaf0334')))));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger('admin_users').error((String('Unable to save/update transaction record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('eb2249de7a2f14690fa849e22b412bfc');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/admin_users_detail/components/ea3c2e918c74b3df6bed778a4734e93e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_transactions_modal_tokens_description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/admin_users_detail/components/e0082069052b7f859c794faeb9e4dafe/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('admin_users_detail_transactions_modal_amount_description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadEvents() {
  ___arguments.context.pageData['events'] = (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, 'Event', (['Startdate>=NOW() AND relatedCompany.Users[Company].objectId=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\''].join('')), null, ['relatedCertification', 'relatedCompany', null], ['Name', 'Startdate', 'URL', 'visible', 'image', 'price'], null, 1, null, 'Startdate DESC', null, null, null, null));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'events')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}

/**
 * Describe this function...
 */
async function loadTools() {
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('704811be2bc673760c6ce8db236c847f', (await Backendless.Data.of('Certification').find(Backendless.DataQueryBuilder.create().setProperties(['objectId', 'name']).setSortBy('name').setPageSize(50))));
}

/**
 * Describe this function...
 */
async function loadCompany() {
  ___arguments.context.pageData['userCompany'] = ((await Backendless.Data.of('Company').find(Backendless.DataQueryBuilder.create().setWhereClause((['Users[Company].objectId=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\''].join(''))).setProperties(['objectId', 'name']).setPageSize(1)))[0]);
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Instructor', ___arguments.context.appData);
  try {
    ___arguments.context.pageData['isOpenModal'] = false;
    ___arguments.context.pageData['pageSize'] = 5;
    ___arguments.context.pageData['pageOffset'] = 0;
    await loadCompany();
    await loadEvents();
    await loadTools();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger('events').debug((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/events/components/8b99ed8ea00f2315c8257edf3b77f837/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_new'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/events/components/abeb1ea77bed7f1ff64975df2f274ebf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/events/components/13faaaf25be4153a2b04edaaddede0d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/events/components/0f5bc183b009fb611d32bd4297252b83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/events/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_subheader'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_relatedcertification'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_startdate'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/88795c81146ddd4765628ff3b11bf78c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'objectId')) ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_edit')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_new')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/e0e47fbfb6cabd77619a456dbe134015/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_name'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/events/components/5bdf63331fd1feff40f7a844ec12d8fa/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_startdate'))

  },
  /* handler:onLabelAssignment */
  /* handler:onMinDateAssignment */
  ['onMinDateAssignment'](___arguments) {
      return (new Date())

  },
  /* handler:onMinDateAssignment */
  /* content */
}))

define('./pages/events/components/704811be2bc673760c6ce8db236c847f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_relatedcertification'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['relatedCertification'] = ((await asyncListFilter(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).options })('704811be2bc673760c6ce8db236c847f')), async (item) => {


   return ((getObjectProperty(item, 'objectId')) == ___arguments.value);
  }))[0]);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/events/components/0bee449762fceeb2378df4e21ce88b56/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_url'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var returnVal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  returnVal = [];
  if (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'URL')) === 'string') {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'URL')).match((new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'ig')))) {
      ___arguments.context.pageData['inputError'] = false;
    } else {
      ___arguments.context.pageData['inputError'] = true;
      addItemToList(returnVal, 'error');
    }
  }

  return returnVal

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/events/components/1094c4b73cc73bed6e31d2c219a2fc93/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'inputError'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/events/components/e26c035de9466413ccac08d77461ba27/bundle.js', [], () => ({
  /* content */
  /* handler:onButtonLabelAssignment */
  async ['onButtonLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image'))

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/images/events/'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['image'] = (await BackendlessUI.Functions.Custom['fn_12d3035a93372f43c859416bf8a2ec89']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image')), ___arguments.uploadedFiles));
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('e26c035de9466413ccac08d77461ba27');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onFileNameAssignment */
  async ['onFileNameAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_28b9f8ff56eaf2b3acc180ca591e924e'](___arguments.file))

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
    var error;


  await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('e26c035de9466413ccac08d77461ba27');
  Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to upload file:\n') + String(error)));

  },
  /* handler:onUploadFail */
  /* handler:onUploadSuccessMsgAssignment */
  async ['onUploadSuccessMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_success'))

  },
  /* handler:onUploadSuccessMsgAssignment */
  /* handler:onUploadFailMsgAssignment */
  async ['onUploadFailMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_error'))

  },
  /* handler:onUploadFailMsgAssignment */
  /* handler:onUploadProgressMsgAssignment */
  async ['onUploadProgressMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_ongoing'))

  },
  /* handler:onUploadProgressMsgAssignment */
  /* content */
}))

define('./pages/events/components/3244f5a194b70a4f861b759d4e3f37b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('visible'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/584ded5bbc7ae611bf43594df7162ee6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('visible'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/6c985709117168f9cc5acb3b7e17df5d/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, upsertEvent, isNew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    isNew = !(typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'string');
    if (isNew) {
      ___arguments.context.dataModel['relatedCompany'] = (getObjectProperty(___arguments.context.pageData, 'userCompany'));
    }
    ___arguments.context.dataModel['image'] = (await BackendlessUI.Functions.Custom['fn_e10e114ea5dd45059eb63d1c004a6294']('oldFile_image', 'image', 'newFile_image', ___arguments.context.dataModel));
    upsertEvent = (await Backendless.Data.of('event').deepSave( ___arguments.context.dataModel ));
    if (isNew) {
      ___arguments.context.pageData['events'] = ([upsertEvent].concat((getObjectProperty(___arguments.context.pageData, 'events'))));
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'events')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', true);
    }
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('6c985709117168f9cc5acb3b7e17df5d');

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.dataModel['visible'] = true;
  ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/events/components/eaa14cb931ff1a6c85682bbdafc90d36/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'Startdate')), true))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/fa4af3488e2ebd7112884399a603cacc/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_price'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/events/components/aadfccfdb0b737148641ab4c4c1bd41e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var priceVar;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  priceVar = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'price'));
  if (!(typeof priceVar === 'number' && !isNaN(priceVar))) {
    priceVar = (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_price_null'));
  }

  return priceVar

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/e098e4b25a65c30fca33f80598c3ca16/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'visible')) ? 'check_circle' : 'radio_button_unchecked')

  },
  /* handler:onIconAssignment */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c'](((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'visible')) ? 'visible_true' : 'visible_false')))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/events/components/da918ff38e48687259b3f6d4fa4f9374/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_edit'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/events/components/b134266ae17228d51712338358c29229/bundle.js', [], () => ({
  /* content */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_delete'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('caf679359136d66bc4c3d3ecbe6b0f24');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/events/components/caf679359136d66bc4c3d3ecbe6b0f24/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_event'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_event'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('caf679359136d66bc4c3d3ecbe6b0f24');
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    await Backendless.Data.of('Event').remove( (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) );
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'events')), (getObjectProperty(___arguments.context.pageData, 'selectedRow')), '');
    if ((getObjectProperty(___arguments.context.pageData, 'events')).length == 0) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
    }
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (String((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error'))) + String(error)), 'error');
    Backendless.Logging.getLogger('events').error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('caf679359136d66bc4c3d3ecbe6b0f24');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/events/components/1206a98860953408f55fa629777a66fb/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image'))));
      delete ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['newFile_image'];
    } else {
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['oldFile_image'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image'));
      ___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d')['image'] = null;
    }

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, 'delete_error', 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/events/components/a4a4590c171eb701b5b60f59053a636f/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image')))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })(((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) ? (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'newFile_image')) : (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6c985709117168f9cc5acb3b7e17df5d'), 'image'))), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/events/components/881d640cdb392c065a15f23c01eede5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['search'] = null;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'events')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/events/components/a33e2d05d397ed89ffb230a54aa60d2c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (___arguments.value.length > 0) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'events')), async (item) => {


     return ((await BackendlessUI.Functions.Custom['fn_4e95111538c25577e3d842139bfd1717']((getObjectProperty(item, 'Name')), ___arguments.value)) || (await BackendlessUI.Functions.Custom['fn_4e95111538c25577e3d842139bfd1717']((getObjectProperty(item, 'relatedCertification.name')), ___arguments.value)));
    })));
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'events')));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/events/components/6ce404793588f5dfb4d17a531a2e111f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_price'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/events/components/68e7fac80c141c123ab152a7be4edbaa/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var number;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_dbc600b974764941180ab22b22b4f9b9']((getObjectProperty(___arguments.context.pageData, 'pageSize')), (getObjectProperty(___arguments.context.pageData, 'tableSize')), (getObjectProperty(___arguments.context.pageData, 'currentPage'))))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['events'] = ((getObjectProperty(___arguments.context.pageData, 'events')).concat((await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, ((getObjectProperty(___arguments.context.pageData, 'currentPage')) + 1)))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (getObjectProperty(___arguments.context.pageData, 'events')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/events/components/01dfbe3c085264715f0f884a2b8f7d03/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentPage')) < (getObjectProperty(___arguments.context.pageData, 'pageCount')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/events/components/b5d7d6f20bf63893dbcae86ac81e8314/bundle.js', [], () => ({
  /* content */
  /* handler:placeholderLogic */
  async ['placeholderLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_description_placeholder'))

  },
  /* handler:placeholderLogic */
  /* content */
}))

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var count, newsItem, date, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadNews() {
  ___arguments.context.pageData['news'] = (await Backendless.Data.of('News').find(Backendless.DataQueryBuilder.create().setWhereClause('fromDate<=NOW() AND (toDate is null OR toDate>NOW())').setProperties(['author.name as authorName', '*']).setSortBy('fromDate DESC').setPageSize(5)));
  count = 1;
  while (count == 1) {
    newsItem = (getObjectProperty(___arguments.context.pageData, 'news'))[(count - 1)];
    date = (getObjectProperty(newsItem, 'fromDate'));
    ___arguments.context.pageData['latestNews'] = date;
    if (date > (getObjectProperty(___arguments.context.pageData, 'currentUser.lastRead'))) {
      newsItem['display'] = true;
    }
    count = (typeof count == 'number' ? count : 0) + 1;
  }
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('2251f2a74831aabb112d91014bbf77de', (getObjectProperty(___arguments.context.pageData, 'news')));
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Instructor', ___arguments.context.appData);
  try {
    await loadNews();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('8d271ddaa475f7ea5bc9cc9830bfa75c', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/landing/components/c5b749985324476962630558fe23e9ce/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f0ba7e74c7263bf9454a5be912a9f2d4'), 'fromDate')), true))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/d20404480375afffa8f1bb59d77e9d29/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('events', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/1065feac1631c69dcaa23b3611e393c2/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('home_button_presentations'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/743919c444ff05896db6385d552ef60b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('presentations', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/3cb5b1b942a81d5af8ae7bf5149e2cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('home_button_events'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/cca190949ab2bd4b85354f419b561394/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('tools', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/79492b0e55b168f6a3d3ee4bf6ebc6d9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('home_subheader_news'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/160dd77bb3127e2120836036c3a6fa74/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('home_button_tools'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/landing/components/ea8a7095feeb8aaa2d56c28e3b554436/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      console.log('Container:');
  console.log((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('2251f2a74831aabb112d91014bbf77de'));
  console.log('Repeater:');
  console.log((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('2251f2a74831aabb112d91014bbf77de'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/1db33278c8779a7d435fb473b1f81be8/bundle.js', [], () => ({
  /* content */
  /* handler:onIconAssignment */
  ['onIconAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f0ba7e74c7263bf9454a5be912a9f2d4'), 'display')) ? 'expand more' : 'keyboard arrow right')

  },
  /* handler:onIconAssignment */
  /* content */
}))

define('./pages/landing/components/f0ba7e74c7263bf9454a5be912a9f2d4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.dataModel, 'display')) != true) {
    ___arguments.context.dataModel['display'] = true;
  } else {
    ___arguments.context.dataModel['display'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/d1ca2af3bdb2f26f1309cb63106db28d/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('news_markread'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'latestNews')) > (getObjectProperty(___arguments.context.pageData, 'currentUser.lastRead')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  currentUser['lastRead'] = (new Date());
  ___arguments.context.pageData['currentUser'] = (await Backendless.UserService.update( new Backendless.User(currentUser) ));
  localStorage.setItem('currentUser', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'currentUser'))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/landing/components/3d5fc5ab0efffc71792cad68a836918f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('news_date'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/landing/components/a396feb9929f7b853908ad438094ca8c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('news_by'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, user, userRoles, password, username, logging, buttonLogin;

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, true  ));
    userRoles = (await Backendless.UserService.getUserRoles());
    if (!((userRoles.includes('Instructor')) || (userRoles.includes('Administrator')))) {
      user = null;
      throw (new Error((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_access_denied'))))
    }

  } catch (error) {
    await setError(error);

  }
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_submit_wait')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_submit')));
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error((await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_username_password_missing')))));
  }
  if (user !== null && !Array.isArray(user) && (typeof user === 'object')) {
    ___arguments.context.appData['currentUser'] = user;
    ___arguments.context.appData['currentUserRoles'] = userRoles;
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('currentUserRoles', JSON.stringify(userRoles));
    await ( async function (language) { return BackendlessUI.I18n.setLanguage(language) })((getObjectProperty(user, 'blUserLocale')));
    if (!(userRoles.includes('Instructor'))) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);
    }
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/6d7b09fc8c04707fa6291e69b80c05d1/bundle.js', [], () => ({
  /* content */
  /* handler:onTextAssignment */
  async ['onTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_password_forgot'))

  },
  /* handler:onTextAssignment */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_d527e6f438ec092dc9685e5f3d479973']('passwordRecovery', ({ 'email': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'username')) })))

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/login/components/084dbfe3f4a3ff238fafb52d27541329/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_username'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/login/components/76322aeb0ccf9526c9f5bf4369c299da/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_password'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/login/components/ba16b59acd59a4e437df50f48e060e7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('login_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (typeof (getObjectProperty(___arguments.context.pageData, 'email')) === 'string') {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['username'] = (getObjectProperty(___arguments.context.pageData, 'email'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/passwordRecovery/components/4641c8cb6f0597610bb5b1fce8828044/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  await Backendless.UserService.restorePassword((getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'emailInput')));
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('54d998a5d0432bf6521c5ad68ed8bf3a', false);
  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f9341517b24cc1ad1a2fe9aced41b929', true);

  },
  /* handler:onClick *//* content */
}));

define('./pages/passwordRecovery/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'email')) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['emailInput'] = (getObjectProperty(___arguments.context.pageData, 'email'));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/passwordRecovery/components/c88214cc934d4d8fa7bb2d71068a7ca3/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_d527e6f438ec092dc9685e5f3d479973']('login', ({ 'email': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'emailInput')) })))

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/passwordRecovery/components/0dca19d352dd33f2c997a3f8553cdd35/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  async ['onTargetPathAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_d527e6f438ec092dc9685e5f3d479973']('login', ({ 'email': (getObjectProperty(___arguments.context.getComponentDataStoreByUid('85dae34486144e2efa2d934bfdddb172'), 'emailInput')) })))

  },
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/presentations/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error, categories;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadPresentations() {
  ___arguments.context.pageData['presentations'] = (await Backendless.Data.of('Presentation').find(Backendless.DataQueryBuilder.create().setProperties(['relatedCertification.name as category', '*']).setRelationsDepth(1).setSortBy('category, name').setPageSize(100)));
  categories = (await BackendlessUI.Functions.Custom['fn_7d3832eea52ef14867f338be94ef03a3']((await BackendlessUI.Functions.Custom['fn_06015457019acdc895570cb6ee4183f7'](((getObjectProperty(___arguments.context.pageData, 'presentations')).map(item => item['category'])))), 'category'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('007199df4cab994a6328b50b4dadd6f6', categories);
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('007199df4cab994a6328b50b4dadd6f6')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('07e3c766d40a3f5f41e3c016cdcc9893', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('007199df4cab994a6328b50b4dadd6f6', false);
  }
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Instructor', ___arguments.context.appData);
  try {
    await loadPresentations();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger('events').debug((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/presentations/components/8527abd32f023ce4518471b1dc927477/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('521abcd570db70e81499a2c3fd151274'), 'powerpoint')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/presentations/components/f02e2296f9cefcdc76fcc6d4181e9e05/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('521abcd570db70e81499a2c3fd151274'), 'pdf')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/presentations/components/ebb84a856e2ff9aec635026612bdc2d3/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  async ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_601d78964cc4bd6784d831c87d94f8ee']((getObjectProperty(___arguments.context.pageData, 'presentations')), 'category', (getObjectProperty(___arguments.context.getComponentDataStoreByUid('b3085b4a4b789c2215e1b662b1115e01'), 'category'))))

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/presentations/components/d4e9cad31573f4518b4464efcd2fb6c1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_notfound_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/63838cb800c305def0a3bd9ee1edf7d7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_notfound_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/9e28886eefabbf7ee0984d3202478925/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/89530b03c7d89bffc78ad9488ce9e886/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_info_about_subheader'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/5c0dad2a5852b3d9ab6c0fc43349a1cf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_info_about_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/e522da72c3139cdd48e809d789df6275/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_info_copyright_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/fb7b19da9e586b9cb332af8ed271dd1a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_info_copyright_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/d9d19b62dd6b64e33c74c7a810b15e0b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_info_use_subheader'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/005957bf83a8858fc0dadbacfb2619ed/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_info_use_text'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/presentations/components/fe41d719d354beb8760612abef29cb25/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(___arguments.context.getComponentDataStoreByUid('521abcd570db70e81499a2c3fd151274'), 'keynote')), true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/presentations/components/a17634754d5140260e2366819feb191d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('presentations_subheader_changelog'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadCompany() {
  ___arguments.context.pageData['Company'] = ((await Backendless.Data.of('Company').find(Backendless.DataQueryBuilder.create().setWhereClause((['Users[Company].objectId=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\''].join(''))).setPageSize(1)))[0]);
}

/**
 * Describe this function...
 */
async function loadCerts() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3e8d125956c426a921fb289bb0d73769', (await Backendless.Data.of('User_Certification').find(Backendless.DataQueryBuilder.create().setWhereClause((['Users[Related_Certifications].objectId=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\''].join(''))).setProperties(['certifiedFromDate', 'certifiedToDate', 'note']).setRelated(['relatedCertification']).setSortBy('certifiedFromDate').setPageSize(10))));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3e8d125956c426a921fb289bb0d73769')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3e8d125956c426a921fb289bb0d73769', false);
  }
}

/**
 * Describe this function...
 */
async function loadTrans() {
  ___arguments.context.pageData['transactions'] = (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, 'User_Transaction', (['Users[Related_Transactions].objectId=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\''].join('')), null, null, ['transId', 'status', 'tokens', 'unitPrice', 'created', 'note'], null, null, null, 'created DESC', null, null, null, null));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('705dbeffe35610a261ff5ebcbdaf0334', (getObjectProperty(___arguments.context.pageData, 'transactions')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('705dbeffe35610a261ff5ebcbdaf0334')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff9412e5adc30d4177baaf7829c414f5', true);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('705dbeffe35610a261ff5ebcbdaf0334', false);
  }
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, null, ___arguments.context.appData);
  try {
    ___arguments.context.pageData['pageSize'] = 25;
    ___arguments.context.pageData['pageOffset'] = 0;
    ___arguments.context.pageData['errorForm'] = [];
    await loadCompany();
    await loadCerts();
    await loadTrans();

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('load_error')), 'error');
    Backendless.Logging.getLogger('events').error((String('Unable to load necessary page data:\n') + String(error)));

  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/profile/components/eaa14cb931ff1a6c85682bbdafc90d36/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'certifiedFromDate')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/e8efba83e982561d157299e802f6cc0a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'certifiedToDate')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/4ad5df910f26fc0fbf21f67ae3619d7c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'relatedCertification')), 'name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/0b486848694345336a65c855fa2c2081/bundle.js', [], () => ({
  /* content */
  /* handler:onDirectoryAssignment */
  ['onDirectoryAssignment'](___arguments) {
      return '/images/profiles/'

  },
  /* handler:onDirectoryAssignment */
  /* handler:onFileNameAssignment */
  ['onFileNameAssignment'](___arguments) {
    var filename;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  filename = [(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'objectId')),'.',subsequenceFromStartLast((getObjectProperty(___arguments.file, 'type')), (((getObjectProperty(___arguments.file, 'type')).lastIndexOf('/') + 1 + 1) - 1))].join('');

  return filename

  },
  /* handler:onFileNameAssignment */
  /* handler:onUploadSuccess */
  async ['onUploadSuccess'](___arguments) {
    var currentUser, error, newImage, oldImage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
    newImage = ___arguments.uploadedFiles;
    oldImage = (getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage'));
    if (newImage != oldImage && oldImage != null) {
      await Backendless.Files.remove(encodePath(oldImage));
    }
    currentUser['profileImage'] = newImage;
    ___arguments.context.pageData['currentUser'] = (await Backendless.UserService.update( new Backendless.User(currentUser) ));
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('0b486848694345336a65c855fa2c2081');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success_profileimage')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error(error);

  }

  },
  /* handler:onUploadSuccess */
  /* handler:onUploadFail */
  async ['onUploadFail'](___arguments) {
    var error;


  await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_error')), 'error');
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('0b486848694345336a65c855fa2c2081');
  Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to upload file:\n') + String(error)));

  },
  /* handler:onUploadFail */
  /* handler:onButtonLabelAssignment */
  async ['onButtonLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_button_image'))

  },
  /* handler:onButtonLabelAssignment */
  /* handler:onUploadSuccessMsgAssignment */
  async ['onUploadSuccessMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_success'))

  },
  /* handler:onUploadSuccessMsgAssignment */
  /* handler:onUploadFailMsgAssignment */
  async ['onUploadFailMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_error'))

  },
  /* handler:onUploadFailMsgAssignment */
  /* handler:onUploadProgressMsgAssignment */
  async ['onUploadProgressMsgAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('upload_image_ongoing'))

  },
  /* handler:onUploadProgressMsgAssignment */
  /* content */
}))

define('./pages/profile/components/3908a154ebd67b72e98e90f1453b1c04/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    ___arguments.context.appData['currentUser'] = (await Backendless.UserService.update( new Backendless.User((getObjectProperty(___arguments.context.pageData, 'currentUser'))) ));
    localStorage.setItem('currentUser', JSON.stringify((getObjectProperty(___arguments.context.appData, 'currentUser'))));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).debug(error);

  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_button_update'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'errorForm')))) && (getObjectProperty(___arguments.context.pageData, 'errorForm')).length > 0)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/profile/components/37b44769f6de056e1e3c19b5e176acde/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_subheader_aboutme'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/5afe68b8aaaf07a7f123b5330ee7d06c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_subheader_otherinfo'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/edf155f76edd92b56cbc4ae9a80daab9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5419acce7db3ee3daecc339a16e78129'), 'created')))).toLocaleString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/e248dfa3bdd8b153651b43fd4f9b5a81/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']((String('status_') + String((getObjectProperty(___arguments.context.getComponentDataStoreByUid('5419acce7db3ee3daecc339a16e78129'), 'status')).toLowerCase()))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/a49e49362c74d1065538fd6778c3616d/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var profileImage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  profileImage = String(Backendless.appPath) + String('/files/images/profiles/default.svg');
  if (getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage')) {
    profileImage = (getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage'));
  }

  return profileImage

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/profile/components/1a6ce3a2b0950eaaf98476915a154e54/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}


  try {
    if (getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage')) {
      await Backendless.Files.remove(encodePath((getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage'))));
    }
    (getObjectProperty(___arguments.context.pageData, 'currentUser'))['profileImage'] = null;
    ___arguments.context.pageData['currentUser'] = (await Backendless.UserService.update( new Backendless.User(({ 'objectId': (getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'profileImage': null })) ));
    localStorage.setItem('currentUser', JSON.stringify((getObjectProperty(___arguments.context.pageData, 'currentUser'))));

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to delete file/update record:\n') + String(error)));

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/95d596ff1e3306b841f7de7a82ee5952/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/profile/components/19fafe6d336822c8bbd710b9d609a71b/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_firstname'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/profile/components/6982bd1e67b16938ce239fb89faad5d8/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_language'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'currentUser'))['blUserLocale'] = ___arguments.value;
  await ( async function (language) { return BackendlessUI.I18n.setLanguage(language) })(___arguments.value);

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/profile/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_subheader_certifications'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_area'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_fromdate'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/3244f5a194b70a4f861b759d4e3f37b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_todate'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/097760d9f1a9b713da88b8e7d50ef32e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_note'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/5dbb54355cc5af92c08757ecfa4f1a43/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_subheader_transactions'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/db4d9ca8b108083362c1bf879d7b5e66/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_status'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/e69420ff10db4da6b129397181418d22/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_orderdate'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/57137ce17669dc3ffc03a50f1bcf097f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_count'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/9848c5bd6272423b0925ddbb6e41b0c2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_note'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/416ae747fef648b97678efcd4c5b76fc/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_label_company'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/6e84e40fbc3bbf84bef920469d45a884/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_label_orgnumber'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/47972176081b79f5f3494345b34a1fc7/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_email'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/profile/components/9f71024ab8ccf30a65149ff2555f97b8/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_countrycode'))

  },
  /* handler:onLabelAssignment */
  /* handler:onPlaceholderAssignment */
  async ['onPlaceholderAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_countrycode_placeholder'))

  },
  /* handler:onPlaceholderAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if ((new RegExp('^(?:\\+|00)\\d{1,3}$', 'g')).test(___arguments.value)) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'errorForm')), 'countrycode', '');
  } else {
    if (!((getObjectProperty(___arguments.context.pageData, 'errorForm')).includes('countrycode'))) {
      addItemToList((getObjectProperty(___arguments.context.pageData, 'errorForm')), 'countrycode');
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/profile/components/698762bd9bd80b12f4d798e7521467ad/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_mobile'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if ((new RegExp('^\\d{8,14}$', 'g')).test(___arguments.value)) {
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'errorForm')), 'mobile', '');
  } else {
    if (!((getObjectProperty(___arguments.context.pageData, 'errorForm')).includes('mobile'))) {
      addItemToList((getObjectProperty(___arguments.context.pageData, 'errorForm')), 'mobile');
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/profile/components/dafc94b34d450674437edc72e0ad5d98/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_dbc600b974764941180ab22b22b4f9b9']((getObjectProperty(___arguments.context.pageData, 'pageSize')), (getObjectProperty(___arguments.context.pageData, 'tableSize')), (getObjectProperty(___arguments.context.pageData, 'currentPage'))))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['transactions'] = ((getObjectProperty(___arguments.context.pageData, 'transactions')).concat((await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, ((getObjectProperty(___arguments.context.pageData, 'currentPage')) + 1)))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('705dbeffe35610a261ff5ebcbdaf0334', (getObjectProperty(___arguments.context.pageData, 'transactions')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/b26b79c468510cac675faa9442a10e95/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentPage')) < (getObjectProperty(___arguments.context.pageData, 'pageCount')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/profile/components/06637fe8e89a8c07a40f81dcd1134579/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('85d808e51dc37538759c644636363726');

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_button_password'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/profile/components/ef585c2b3d3c0dcb84ebef79d91428ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('bc88fef8aa5e37b7cb7a39eeff3ea0ae');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('85d808e51dc37538759c644636363726');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/profile/components/c93886c9105213bdff65d99a802a1b42/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_modal_password_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/375b53e3b87271e32af45ab9e2441fdb/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_modal_password_new'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/profile/components/0b83b05cf2acc1cd2d9def2c28863bfd/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_modal_password_verify'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/profile/components/ab7ee7aac92ab658ba7efa0b74f49319/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_modal_password_submit'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  async ['onDisabledStateAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'newPassword')) === 'string') && (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'confirmPassword')) === 'string'))) {
    returnValue2 = true;
  } else {
    returnValue2 = !(await BackendlessUI.Functions.Custom['fn_15c58d25adbd4386f92be77926950ab5']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'newPassword')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'confirmPassword'))));
  }

  return returnValue2

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/profile/components/bc88fef8aa5e37b7cb7a39eeff3ea0ae/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, user;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    user = (await Backendless.Request.post(`${Backendless.appPath}/services/userAdmin/changePassword`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'currentPassword': (getObjectProperty(___arguments.context.dataModel, 'currentPassword')),'newPassword': (getObjectProperty(___arguments.context.dataModel, 'newPassword')) }));
    ___arguments.context.pageData['currentUser'] = (await Backendless.UserService.login( (getObjectProperty(user, 'email')), (getObjectProperty(___arguments.context.dataModel, 'newPassword')), true  ));
    ___arguments.context.appData['currentUser'] = user;
    localStorage.setItem('currentUser', JSON.stringify(user));
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('bc88fef8aa5e37b7cb7a39eeff3ea0ae');
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('85d808e51dc37538759c644636363726');
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('password_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('password_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/profile/components/9b0bb5416d474db49137778a908995fb/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_modal_password_current'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/profile/components/588f2bc3ac7429abdcf30123dc444343/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('password_requirements'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/bf59ca717657f649500a149cb618782d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_modal_password_error'))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnValue2 = false;
  if ((typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'newPassword')) === 'string') && (typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'confirmPassword')) === 'string')) {
    if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'newPassword')).length >= 8 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'confirmPassword')).length >= 8 && !(await BackendlessUI.Functions.Custom['fn_15c58d25adbd4386f92be77926950ab5']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'newPassword')), (getObjectProperty(___arguments.context.getComponentDataStoreByUid('bc88fef8aa5e37b7cb7a39eeff3ea0ae'), 'confirmPassword'))))) {
      returnValue2 = true;
    }
  }

  return returnValue2

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/profile/components/ea6b511b927f58fb62f6b69f2aa1228b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_unitprice'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/a73c9acadf195e13a5ec13da3275d80d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_header_orderid'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/profile/components/73a96b93e5accbf6dad979cda3adc2a1/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_lastname'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/profile/components/a364b837002b235dc83d40ab1342b036/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('profile_label_tokens'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/support/components/ec06445240035581f13ed03f9b3479dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://dynamic.eu.teamwork.com/p/forms/BdVdgGkCWpG52wdzpzxM', true, undefined);

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('support_button'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function loadAssessments() {
  ___arguments.context.pageData['assessments'] = (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, 'RelCo_Assessment', (['Rel_Instructor.objectId=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\''].join('')), null, ['Rel_User', 'Rel_Instructor'], null, null, null, null, 'updated DESC', null, null, null, null));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (getObjectProperty(___arguments.context.pageData, 'assessments')));
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3698e84fd22c6b0fb87c4c1d8518b06c')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3698e84fd22c6b0fb87c4c1d8518b06c', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
  }
}

/**
 * Describe this function...
 */
async function loadColleagues() {
  ___arguments.context.pageData['colleagues'] = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['Company.Users[Company].objectId=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\' AND objectId!=\'',(getObjectProperty(___arguments.context.pageData, 'currentUser.objectId')),'\''].join(''))).setProperties(['objectId', 'name']).setSortBy('lastname, firstname').setPageSize(100)));
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('a0bce5ca6d06ea0be53b7feb1cc68dec', (getObjectProperty(___arguments.context.pageData, 'colleagues')));
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Instructor', ___arguments.context.appData);
  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['isOpenModalTransfer'] = false;
  ___arguments.context.pageData['pageSize'] = 50;
  ___arguments.context.pageData['pageOffset'] = 0;
  ___arguments.context.pageData['selectedRows'] = [];
  await loadAssessments();
  await loadColleagues();

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/tools/components/8b99ed8ea00f2315c8257edf3b77f837/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = null;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('dc01057a88098af42722fee775230947');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_new'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentUser.numTokens')) <= 0)

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_tooltip_notokens'))

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onTooltipVisibilityAssignment */
  ['onTooltipVisibilityAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentUser.numTokens')) <= 0)

  },
  /* handler:onTooltipVisibilityAssignment */
  /* content */
}))

define('./pages/tools/components/abeb1ea77bed7f1ff64975df2f274ebf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/13faaaf25be4153a2b04edaaddede0d8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/tools/components/0f5bc183b009fb611d32bd4297252b83/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/0e15dff41e82cf2ccaa2a85dbd38ab43/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var numTokens, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
    numTokens = (Number(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).value })('6cf2b4a7da2e295a41abb8f78474ef92'))));
    currentUser = (await Backendless.Request.put(`${Backendless.appPath}/services/toolsAdmin/logTransaction`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send({ 'numTokens': numTokens,'purchase': true }));
    ___arguments.context.pageData['currentUser'] = currentUser;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/tools/components/5b32cd0dd848547c83660ee92a3d82f7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_text_otherinfo'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/5afe68b8aaaf07a7f123b5330ee7d06c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_subheader_otherinfo'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/5e7743af7ea1bad1b8b9d59f0d94d57a/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_respondent_firstname'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/cf5b76715293e267e6719d095dc85b59/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_respondent_lastname'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/190f0ca7e692c282e43af4796303db4d/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_respondent_email'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/ecc7a95f0fdf4845fa2adbdeff4ef209/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_own_manager'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'objectId'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/tools/components/2c72920e807cc696dc2405bbc065bc22/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_number'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var lastValue, newValue, startedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  startedValue = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'startedColleague'));
  if (startedValue < 2) {
    startedValue = 2;
  }
  newValue = ___arguments.value;
  if (newValue < 0) {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numColleague'] = 0;
  } else if (newValue > 0 && newValue < 2) {
    lastValue = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'tempValue'));
    if (lastValue > 0) {
      ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numColleague'] = 0;
    } else {
      ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numColleague'] = 2;
    }
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numColleague')) < startedValue) {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numColleague'] = startedValue;
  }
  ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['tempValue'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numColleague'));

  },
  /* handler:onChange */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['tempValue'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('2c72920e807cc696dc2405bbc065bc22')), 'value'));

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/tools/components/fec4ee21b651a868323e9007f0b32046/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_own_single'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'objectId'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/tools/components/2f7e54a327b7c1397c62b4ce7956a5da/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/fe5ab784fedbcd85f569613b7c3fc855/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_number'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var newValue, startedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  startedValue = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'startedManager'));
  newValue = ___arguments.value;
  if (newValue < 0) {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numManager'] = 0;
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numManager')) < startedValue) {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numManager'] = startedValue;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/tools/components/12ede1378d555cca92391e855352336f/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_number'))

  },
  /* handler:onLabelAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var lastValue, newValue, startedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  startedValue = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'startedEmployee'));
  newValue = ___arguments.value;
  if (startedValue > 0 && startedValue < 5) {
    startedValue = 5;
  }
  if (newValue < 0) {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numEmployee'] = 0;
  } else if (newValue > 0 && newValue < 5) {
    lastValue = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'tempValue'));
    if (lastValue > 0) {
      ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numEmployee'] = 0;
    } else {
      ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numEmployee'] = 5;
    }
  }
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numEmployee')) < startedValue) {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numEmployee'] = startedValue;
  }
  ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['tempValue'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numEmployee'));

  },
  /* handler:onChange */
  /* handler:onFocus */
  ['onFocus'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['tempValue'] = (getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('12ede1378d555cca92391e855352336f')), 'value'));

  },
  /* handler:onFocus */
  /* content */
}))

define('./pages/tools/components/a034562717972c4b3f515839b7dfd257/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'selectedRow.objectId')) === 'string') ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_edit')) : (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_new')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/aa3649dadd2220445d9c6d573e824fc1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var numCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  numCount = 1;
  numCount = (typeof numCount == 'number' ? numCount : 0) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numColleague'));
  numCount = (typeof numCount == 'number' ? numCount : 0) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numManager'));
  numCount = (typeof numCount == 'number' ? numCount : 0) + (getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numEmployee'));

  return numCount

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/0ff9b4076da1c73cca9f116fce46f0eb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_colleague'))

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'colleagueCount')) > 0 ? ['selected'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/tools/components/e2c6c255171219b49549c6c844e1ba15/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_manager'))

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'managerCount')) > 0 ? ['selected'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/tools/components/d9db2fc4b69a746614774be15d2bd89d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_employee'))

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'employeeCount')) > 0 ? ['selected'] : [])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/tools/components/d5f54ae739a97e710ad640b686af5236/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_sum'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/dc01057a88098af42722fee775230947/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, upsertAssessment, isNew, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    isNew = !(typeof (getObjectProperty(___arguments.context.dataModel, 'objectId')) === 'string');
    delete ___arguments.context.dataModel['tempValue'];
    upsertAssessment = (await Backendless.Request.put(`${Backendless.appPath}/services/toolsAdmin/upsertAssessment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send(JSON.stringify(___arguments.context.dataModel)));
    currentUser = (getObjectProperty(upsertAssessment, 'Rel_Instructor'));
    if ((getObjectProperty(currentUser, 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'currentUser.objectId'))) {
      ___arguments.context.pageData['currentUser'] = currentUser;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
    if (isNew) {
      ___arguments.context.pageData['assessments'] = ([upsertAssessment].concat((getObjectProperty(___arguments.context.pageData, 'assessments'))));
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (getObjectProperty(___arguments.context.pageData, 'assessments')));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3698e84fd22c6b0fb87c4c1d8518b06c', true);
    } else {
      Object.assign(___arguments.context.dataModel, upsertAssessment);
    }
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRow'] = null;
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('dc01057a88098af42722fee775230947');

  }

  },
  /* handler:onSubmit */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.dataModel['numOwnSingle'] = 1;
  ___arguments.context.dataModel['numOwnManager'] = 0;
  ___arguments.context.dataModel['numColleague'] = 0;
  ___arguments.context.dataModel['numManager'] = 0;
  ___arguments.context.dataModel['numEmployee'] = 0;

  },
  /* handler:onReset */
  /* content */
}))

define('./pages/tools/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_sumavailable'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_sumstarted'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/3244f5a194b70a4f861b759d4e3f37b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_sumcompleted'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/37bf3764c47973394e4984d27e6acc01/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  ___arguments.context.pageData['isOpenModal'] = true;

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_edit'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/tools/components/eb0edcfae11bcdff4f643177c6184af7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.checkedValue == 'OWN_MANAGER') {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numOwnSingle'] = 0;
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numOwnManager'] = 1;
  } else {
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numOwnSingle'] = 1;
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numOwnManager'] = 0;
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numColleague'] = 0;
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numEmployee'] = 0;
    ___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947')['numManager'] = 0;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedValueAssignment */
  ['onCheckedValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('dc01057a88098af42722fee775230947'), 'numOwnSingle')) == 1 ? 'OWN_SINGLE' : 'OWN_MANAGER')

  },
  /* handler:onCheckedValueAssignment */
  /* content */
}))

define('./pages/tools/components/95fb0085c7d3d747bb0c6834e252c427/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'sumStarted')) == 0)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedRow'] = ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb');
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).openModal() })('3bbdd9165108bd8e657bb3f32b08a3fa');

  },
  /* handler:onClick */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('events_delete'))

  },
  /* handler:onTooltipTextAssignment */
  /* content */
}))

define('./pages/tools/components/3bbdd9165108bd8e657bb3f32b08a3fa/bundle.js', [], () => ({
  /* content */
  /* handler:titleLogic */
  async ['titleLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_title_assessment'))

  },
  /* handler:titleLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_content_assessment'))

  },
  /* handler:contentLogic */
  /* handler:submitButtonLogic */
  async ['submitButtonLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_submit'))

  },
  /* handler:submitButtonLogic */
  /* handler:closeButtonLabelLogic */
  async ['closeButtonLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('modal_delete_cancel'))

  },
  /* handler:closeButtonLabelLogic */
  /* handler:onClose */
  async ['onClose'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('3bbdd9165108bd8e657bb3f32b08a3fa');
  ___arguments.context.pageData['selectedRow'] = null;

  },
  /* handler:onClose */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    currentUser = (await Backendless.Request.delete(`${Backendless.appPath}/services/toolsAdmin/deleteAssessment`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': (Backendless.getCurrentUserToken()) }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'selectedRow')))));
    if ((getObjectProperty(currentUser, 'objectId')) == (getObjectProperty(___arguments.context.pageData, 'currentUser.objectId'))) {
      ___arguments.context.pageData['currentUser'] = currentUser;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'assessments')), (getObjectProperty(___arguments.context.pageData, 'selectedRow')), '');
    if ((getObjectProperty(___arguments.context.pageData, 'assessments')).length == 0) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3698e84fd22c6b0fb87c4c1d8518b06c', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
    }
    ___arguments.context.pageData['selectedRow'] = null;
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('delete_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to delete record:\n') + String(error)));

  } finally {
    await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).closeModal() })('3bbdd9165108bd8e657bb3f32b08a3fa');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/tools/components/fef3fe96015ab5f20b546e84a6fc75c9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numOwnSingle')) == 1) {
    returnValue2 = 'tools_relco_own_single';
  } else {
    returnValue2 = 'tools_relco_own_manager';
  }

  return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c'](returnValue2))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/d05f72ad3e3f5a7e555a366b08e1405c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_colleague'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/2d41f7c914de6b746ba83e876bdc8ce3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_manager'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/65f3aee2a6a1462eab8abb65235b61e5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_employee'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/4d5ab96e8e9861eb856ab84d867b87ca/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'startedOwnSingle')) == 1 || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'startedOwnManager')) == 1) {
    returnValue2 = 1;
  } else {
    returnValue2 = 0;
  }

  return returnValue2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/918a78ecd3713b1df7917c6247be8c5e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'completedOwnSingle')) == 1 || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'completedOwnManager')) == 1) {
    returnValue2 = 1;
  } else {
    returnValue2 = 0;
  }

  return returnValue2

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/f45c89c5eeebf1e7915450a729f1a564/bundle.js', [], () => ({
  /* content */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numOwnSingle')) == 1) {
    returnValue2 = 'OWN_SINGLE';
  } else {
    returnValue2 = 'OWN_MANAGER';
  }

  return (await BackendlessUI.Functions.Custom['fn_dddfbe1e57444a153964702ddd038761']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'uniqueCode')), returnValue2))

  },
  /* handler:contentLogic */
  /* handler:onCopy */
  async ['onCopy'](___arguments) {
    var classList, list;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).copy() })('f45c89c5eeebf1e7915450a729f1a564');
  classList = ((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f45c89c5eeebf1e7915450a729f1a564'));
  if (!(classList.includes('buttonClicked'))) {
    addItemToList(classList, 'buttonClicked');
  }

  },
  /* handler:onCopy */
  /* handler:copyLabelLogic */
  async ['copyLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copy'))

  },
  /* handler:copyLabelLogic */
  /* handler:copiedLabelLogic */
  async ['copiedLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copied'))

  },
  /* handler:copiedLabelLogic */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'completedOwnSingle')) == 1 || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'completedOwnManager')) == 1 ? false : true)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/d4082ab89dfcb2f42f790c9be3a6e3cf/bundle.js', [], () => ({
  /* content */
  /* handler:onCopy */
  async ['onCopy'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).copy() })('d4082ab89dfcb2f42f790c9be3a6e3cf');

  },
  /* handler:onCopy */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numColleague')) > 0 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'completedColleague')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numColleague')) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:copyLabelLogic */
  async ['copyLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copy'))

  },
  /* handler:copyLabelLogic */
  /* handler:copiedLabelLogic */
  async ['copiedLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copied'))

  },
  /* handler:copiedLabelLogic */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_dddfbe1e57444a153964702ddd038761']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'uniqueCode')), 'COLLEAGUE'))

  },
  /* handler:contentLogic */
  /* content */
}))

define('./pages/tools/components/591b89efa79e453afa7a03d7768d4ee7/bundle.js', [], () => ({
  /* content */
  /* handler:copyLabelLogic */
  async ['copyLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copy'))

  },
  /* handler:copyLabelLogic */
  /* handler:copiedLabelLogic */
  async ['copiedLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copied'))

  },
  /* handler:copiedLabelLogic */
  /* handler:onCopy */
  async ['onCopy'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).copy() })('591b89efa79e453afa7a03d7768d4ee7');

  },
  /* handler:onCopy */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numManager')) > 0 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'completedManager')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numManager')) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_dddfbe1e57444a153964702ddd038761']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'uniqueCode')), 'MANAGER'))

  },
  /* handler:contentLogic */
  /* content */
}))

define('./pages/tools/components/c6772998e6565f8528e2d165c3e2f855/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numEmployee')) > 0 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'completedEmployee')) < (getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numEmployee')) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:copyLabelLogic */
  async ['copyLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copy'))

  },
  /* handler:copyLabelLogic */
  /* handler:copiedLabelLogic */
  async ['copiedLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_copied'))

  },
  /* handler:copiedLabelLogic */
  /* handler:onCopy */
  async ['onCopy'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).copy() })('c6772998e6565f8528e2d165c3e2f855');

  },
  /* handler:onCopy */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_dddfbe1e57444a153964702ddd038761']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'uniqueCode')), 'EMPLOYEE'))

  },
  /* handler:contentLogic */
  /* content */
}))

define('./pages/tools/components/bf6eebc4b9c985bad27701c7d01583d6/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numColleague')) > 0 ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/0ead3fe15116070aa5b7ee4baa22fc54/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numManager')) > 0 ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/45611824c71560c16703fdeb7857b878/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('118e78cb148fed67e3116c1254944b89'), 'numEmployee')) > 0 ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/4b323a21a7a41e7febff5bed51092985/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_evaluate'))

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('tools_evaluate', ({ 'assessment': ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb') }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'sumCompleted')) > 0)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/170279f61076dd84420f03bdde0b5b05/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_colleague_description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/f332a887e81a39dadf211b1cc5c34b9b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_employee_description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/75b358e1cd85aeaab321cf39d22e29d9/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_respondent_company'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/8c3a801344cca3077124dcd152329d44/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_company'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/25444deb88eed3c4eed2454762ffba3d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_department'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/b31e188c08b944ae9825b2078094bffe/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_respondent_department'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/49686c832b1a46b12e3f5e0e5557e33c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('118e78cb148fed67e3116c1254944b89', ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('118e78cb148fed67e3116c1254944b89')), 'display')) ? false : true));
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')).includes('selected')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected', '');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/f0bb484b5551fc8ff324903911fb2409/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('118e78cb148fed67e3116c1254944b89', ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('118e78cb148fed67e3116c1254944b89')), 'display')) ? false : true));
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')).includes('selected')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected', '');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/917be1bec122f7983b9cf18201d22e92/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var returnVal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnVal = false;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ecc7a95f0fdf4845fa2adbdeff4ef209')), 'checkedValue')) == 'OWN_MANAGER') {
    returnVal = true;
  }

  return returnVal

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/b68dd399776f4fc9734b814d244e0b9d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var returnVal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnVal = false;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ecc7a95f0fdf4845fa2adbdeff4ef209')), 'checkedValue')) == 'OWN_MANAGER') {
    returnVal = true;
  }

  return returnVal

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/66f9fc7bf203901497d8def8d67dbf61/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var returnVal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnVal = false;
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('ecc7a95f0fdf4845fa2adbdeff4ef209')), 'checkedValue')) == 'OWN_MANAGER') {
    returnVal = true;
  }

  return returnVal

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/37b44769f6de056e1e3c19b5e176acde/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_subheader_order'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/e83bd51689558082091a007f18b4bb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_subheader_available'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/6cf2b4a7da2e295a41abb8f78474ef92/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_order_select'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/3908a154ebd67b72e98e90f1453b1c04/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_order_submit'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/b0b380a3c6eeb446ad42676a88ecff74/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['search'] = null;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (getObjectProperty(___arguments.context.pageData, 'assessments')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/439bee1ad715fca2715cb3a79825606c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}


  if (___arguments.value.length > 0) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'assessments')), async (item) => {


     return ((await BackendlessUI.Functions.Custom['fn_4e95111538c25577e3d842139bfd1717']((getObjectProperty(item, 'name')), ___arguments.value)) || (await BackendlessUI.Functions.Custom['fn_4e95111538c25577e3d842139bfd1717']((getObjectProperty(item, 'company')), ___arguments.value)) || (await BackendlessUI.Functions.Custom['fn_4e95111538c25577e3d842139bfd1717']((getObjectProperty(item, 'department')), ___arguments.value)) || (await BackendlessUI.Functions.Custom['fn_4e95111538c25577e3d842139bfd1717']((getObjectProperty(item, 'email')), ___arguments.value)));
    })));
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (getObjectProperty(___arguments.context.pageData, 'assessments')));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/tools/components/99ab65b8e4487132ee43f44e51997a02/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'currentPage')) < (getObjectProperty(___arguments.context.pageData, 'pageCount')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools/components/9b4afdaadf9fcf47fae45fe4a0278c03/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['assessments'] = ((getObjectProperty(___arguments.context.pageData, 'assessments')).concat((await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, ((getObjectProperty(___arguments.context.pageData, 'currentPage')) + 1)))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (getObjectProperty(___arguments.context.pageData, 'assessments')));

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
    var number;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_dbc600b974764941180ab22b22b4f9b9']((getObjectProperty(___arguments.context.pageData, 'pageSize')), (getObjectProperty(___arguments.context.pageData, 'tableSize')), (getObjectProperty(___arguments.context.pageData, 'currentPage'))))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/454661b85ea79d74f6fb9d81df4d327d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'sumCompleted')) == 0)

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onTooltipTextAssignment */
  async ['onTooltipTextAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'sumCompleted')) == 0 ? (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_checkbox_disabled_tooltip')) : '')

  },
  /* handler:onTooltipTextAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value == true) {
    ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb')['selected'] = true;
    addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedRows')), ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'));
  } else {
    ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb')['selected'] = false;
    removeItemInList((getObjectProperty(___arguments.context.pageData, 'selectedRows')), ___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'objectId');
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/tools/components/4ad5df910f26fc0fbf21f67ae3619d7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('118e78cb148fed67e3116c1254944b89', ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('118e78cb148fed67e3116c1254944b89')), 'display')) ? false : true));
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')).includes('selected')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected', '');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/b73e85b606b848eeb012242fc5b12402/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var list;

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('be781cdb8a8f25d8e2e537aa8dc42e36')).includes('clicked')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('be781cdb8a8f25d8e2e537aa8dc42e36')), 'clicked', '');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('be781cdb8a8f25d8e2e537aa8dc42e36')), 'clicked');
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_dropdown'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((Array.isArray((getObjectProperty(___arguments.context.pageData, 'selectedRows')))) && (getObjectProperty(___arguments.context.pageData, 'selectedRows')).length == 0)

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/tools/components/f5078db3d10e616475c5a8da35ef6f03/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var assessment;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  var assessment_list = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3698e84fd22c6b0fb87c4c1d8518b06c'));
  for (var assessment_index in assessment_list) {
    assessment = assessment_list[assessment_index];
    if ((getObjectProperty(assessment, 'sumCompleted')) > 0) {
      if (___arguments.value == true) {
        addItemToList((getObjectProperty(___arguments.context.pageData, 'selectedRows')), assessment);
      } else {
        removeItemInList((getObjectProperty(___arguments.context.pageData, 'selectedRows')), assessment, 'objectId');
      }
      assessment['selected'] = ___arguments.value;
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/tools/components/8db3e2b595ae6184b85ccf965da69532/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_dropdown_compare'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/55d4fbcb41ee29b2b58b6b114cd2ccf7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, prefix;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedRows')).length > 1) {
    prefix = 0;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('tools_compare', ({ 'assessments': (await Promise.all((getObjectProperty(___arguments.context.pageData, 'selectedRows')).map(async item => {  prefix = (typeof prefix == 'number' ? prefix : 0) + 1;
    ; return ({ 'prefix': prefix,'objectId': (getObjectProperty(item, 'objectId')),'name': (getObjectProperty(item, 'name')),'created': (getObjectProperty(item, 'created')) });}))) }));
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var returnVal;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  returnVal = ['dropdown__item'];
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'selectedRows')))) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedRows')).length < 2) {
      returnVal = ['dropdown__item_disabled'];
    }
  }

  return returnVal

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/tools/components/f81c55b7799458147077b26aee82f70e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_transfer'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/3cf30ea2b5a72c8fffe55f571489c538/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isOpenModalTransfer')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/tools/components/a636f78793d12d7ab50a477ba836fec4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classesList;


  ___arguments.context.pageData['isOpenModalTransfer'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/1e1ad3bebcee95252189faedec097a2a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['isOpenModalTransfer'] = true;
  removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('be781cdb8a8f25d8e2e537aa8dc42e36')), 'clicked', '');

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/a8dc714c051a69a154c3823592160f5b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isOpenModalTransfer'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools/components/2b6bcec64b2d16a3d842e117a32198aa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_transfer_info'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools/components/bfd8bdc175c43b497868bea1ec268019/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_transfer_submit'))

  },
  /* handler:onLabelAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.getComponentDataStoreByUid('526241b6d33ee7f6cf4212038cabd40a'), 'selectedUser')) === 'string'))

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./pages/tools/components/a0bce5ca6d06ea0be53b7feb1cc68dec/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  async ['onLabelAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_transfer_user'))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/tools/components/526241b6d33ee7f6cf4212038cabd40a/bundle.js', [], () => ({
  /* content */
  /* handler:onReset */
  ['onReset'](___arguments) {
      ___arguments.context.pageData['isOpenModalTransfer'] = false;
  (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('526241b6d33ee7f6cf4212038cabd40a');

  },
  /* handler:onReset */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var error, updatedAssessmentList, transfer, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  try {
    updatedAssessmentList = (getObjectProperty(___arguments.context.pageData, 'assessments'));
    transfer = (new Backendless.UnitOfWork('REPEATABLE_READ'));
    await ( async () => {
      const _uow = transfer;

      var item_list = (getObjectProperty(___arguments.context.pageData, 'selectedRows'));
      for (var item_index in item_list) {
        item = item_list[item_index];
        _uow.setRelation('RelCo_Assessment', (getObjectProperty(item, 'objectId')), 'Rel_Instructor', ({ 'objectId': (getObjectProperty(___arguments.context.dataModel, 'selectedUser')) }));
        removeItemInList(updatedAssessmentList, item, 'objectId');
      }

    })();

    await transfer.execute().then(function(result) {
        const error = result && result.error

        if (error) {
          throw error
        }

      return result
      });
    ___arguments.context.pageData['assessments'] = updatedAssessmentList;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', updatedAssessmentList);
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_success')), 'success');

  } catch (error) {
    await ( async function (componentUid, isClose, isAction, actionText, text, snackType) {  ___arguments.context.getComponentByUid(componentUid).create(isClose, isAction, actionText, text, snackType) })('7b75016697573c8a596bc69d551e117d', undefined, undefined, undefined, (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('save_error')), 'error');
    Backendless.Logging.getLogger(((function () { return BackendlessUI.Navigator.getCurrentPageName() })())).error((String('Unable to save/update record:\n') + String(error)));

  } finally {
    ___arguments.context.pageData['selectedRows'] = [];
    (function (componentUid) { ___arguments.context.getComponentByUid(componentUid).reset() })('526241b6d33ee7f6cf4212038cabd40a');

  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/tools_compare/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var datasets, labelList, colorChoice, colors, result, prefix, resultSet, assessmentIds, item, managerDataset, employeeDataset, colleagueDataset, ownDataset, data, color, labelPrefix;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function prepareChart(labelPrefix, data, color) {
  labelList = [];
  ownDataset = ({ [`label`]: [labelPrefix,': ',(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_own'))].join(''),[`data`]: [],[`backgroundColor`]: 'transparent',[`borderColor`]: color,[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [0],[`borderDashOffset`]: 0,[`pointRadius`]: 5,[`pointBorderColor`]: 'rgba(255,255,255,1)',[`pointBackgroundColor`]: color,[`pointStyle`]: 'circle' });
  colleagueDataset = ({ [`label`]: [labelPrefix,': ',(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_colleague'))].join(''),[`data`]: [],[`backgroundColor`]: 'transparent',[`borderColor`]: color,[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [2],[`borderDashOffset`]: 2,[`pointRadius`]: 5,[`pointBorderColor`]: 'rgba(255,255,255,1)',[`pointBackgroundColor`]: color,[`pointStyle`]: 'rect',[`hidden`]: true });
  employeeDataset = ({ [`label`]: [labelPrefix,': ',(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_employee'))].join(''),[`data`]: [],[`backgroundColor`]: 'transparent',[`borderColor`]: color,[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [5],[`borderDashOffset`]: 15,[`pointRadius`]: 5,[`pointBorderColor`]: 'rgba(255,255,255,1)',[`pointBackgroundColor`]: color,[`pointStyle`]: 'circle',[`hidden`]: true });
  managerDataset = ({ [`label`]: [labelPrefix,': ',(await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_manager'))].join(''),[`data`]: [],[`backgroundColor`]: 'transparent',[`borderColor`]: color,[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [15],[`borderDashOffset`]: 5,[`pointRadius`]: 5,[`pointBorderColor`]: 'rgba(255,255,255,1)',[`pointBackgroundColor`]: color,[`pointStyle`]: 'triangle',[`hidden`]: true });
  for (var item_index in data) {
    item = data[item_index];
    if (typeof (getObjectProperty(item, 'averageOwn')) === 'number' && !isNaN((getObjectProperty(item, 'averageOwn')))) {
      addItemToList(labelList, ([(getObjectProperty(item, 'topicId')),'. ',(getObjectProperty(item, 'topicName'))].join('')));
      addItemToList((getObjectProperty(ownDataset, 'data')), (getObjectProperty(item, 'averageOwn')));
    }
    if (typeof (getObjectProperty(item, 'averageColleague')) === 'number' && !isNaN((getObjectProperty(item, 'averageColleague')))) {
      addItemToList((getObjectProperty(colleagueDataset, 'data')), (getObjectProperty(item, 'averageColleague')));
    }
    if (typeof (getObjectProperty(item, 'averageEmployee')) === 'number' && !isNaN((getObjectProperty(item, 'averageEmployee')))) {
      addItemToList((getObjectProperty(employeeDataset, 'data')), (getObjectProperty(item, 'averageEmployee')));
    }
    if (typeof (getObjectProperty(item, 'averageManager')) === 'number' && !isNaN((getObjectProperty(item, 'averageManager')))) {
      addItemToList((getObjectProperty(managerDataset, 'data')), (getObjectProperty(item, 'averageManager')));
    }
  }
  if (!!(getObjectProperty(ownDataset, 'data')).length) {
    addItemToList(datasets, ownDataset);
  }
  if (!!(getObjectProperty(colleagueDataset, 'data')).length) {
    addItemToList(datasets, colleagueDataset);
  }
  if (!!(getObjectProperty(employeeDataset, 'data')).length) {
    addItemToList(datasets, employeeDataset);
  }
  if (!!(getObjectProperty(managerDataset, 'data')).length) {
    addItemToList(datasets, managerDataset);
  }
}

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}

/**
 * Describe this function...
 */
async function addMonoRings() {
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('1', 14),[`backgroundColor`]: '#f9faf4',[`borderColor`]: 'transparent',[`fill`]: true,[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('2', 14),[`backgroundColor`]: '#909090',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('3', 14),[`backgroundColor`]: '#a0a0a0',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('4', 14),[`backgroundColor`]: '#b0b0b0',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('5', 14),[`backgroundColor`]: '#c0c0c0',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('6', 14),[`backgroundColor`]: '#d0d0d0',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('7', 14),[`backgroundColor`]: '#e0e0e0',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('8', 14),[`backgroundColor`]: '#f0f0f0',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Instructor', ___arguments.context.appData);
  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['tableSize'] = 20;
  ___arguments.context.pageData['pageSize'] = 20;
  ___arguments.context.pageData['pageOffset'] = 0;
  assessmentIds = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'assessments')).map(async item => {; return (getObjectProperty(item, 'objectId'));})));
  resultSet = (await Backendless.Request.get(`${Backendless.appPath}/services/RelCo/compareAssessments`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'assessments': JSON.stringify((assessmentIds.join(','))) }).send());
  colors = ['#ef3656', '#f9a66c', '#f6cd10', '#62bf84', '#756ab0', '#f9faf4'];
  datasets = [];
  prefix = 0;
  colorChoice = 0;
  ___arguments.context.pageData['chartDatasets'] = [];
  for (var result_index in resultSet) {
    result = resultSet[result_index];
    prefix = (typeof prefix == 'number' ? prefix : 0) + 1;
    colorChoice = (typeof colorChoice == 'number' ? colorChoice : 0) + 1;
    if (colorChoice == 7) {
      colorChoice = 1;
    }
    await prepareChart(prefix, (getObjectProperty(result, 'data')), colors[(colorChoice - 1)]);
  }
  await addMonoRings();
  ___arguments.context.pageData['chartLabels'] = labelList;
  ___arguments.context.pageData['chartDatasets'] = datasets;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/tools_compare/components/e83bd51689558082091a007f18b4bb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_subheader_compare'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_compare/components/0f911cbbf617d4e3346b98034bb85b4a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_4ccdaa2e1646d6ad256b6a533d2d384e']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('d0faf258c562e2f94f629f4c2a562a6a'), 'created')), false))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_compare/components/2e23c475a1370e2f0d541581218e8d22/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('d0faf258c562e2f94f629f4c2a562a6a'), 'prefix')) + String(': '))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var datasets, managerDataset, employeeDataset, colleagueDataset, ownDataset, labelList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function prepareChart() {
  labelList = [];
  ownDataset = ({ [`label`]: (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_own')),[`data`]: [],[`backgroundColor`]: 'rgba(0, 0, 0, 1)',[`borderColor`]: 'rgb(255, 255, 255)',[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [0],[`borderDashOffset`]: 0,[`pointRadius`]: 5,[`pointBorderColor`]: 'transparent',[`pointBackgroundColor`]: 'rgba(255,255,255,1)',[`pointStyle`]: 'circle' });
  colleagueDataset = ({ [`label`]: (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_colleague')),[`data`]: [],[`backgroundColor`]: 'transparent',[`borderColor`]: 'rgb(0, 0, 0)',[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [2],[`borderDashOffset`]: 2,[`pointRadius`]: 5,[`pointBorderColor`]: 'transparent',[`pointBackgroundColor`]: 'rgba(0,0,0,1)',[`pointStyle`]: 'rect',[`hidden`]: true });
  employeeDataset = ({ [`label`]: (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_employee')),[`data`]: [],[`backgroundColor`]: 'transparent',[`borderColor`]: 'rgb(0, 0, 0)',[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [0],[`borderDashOffset`]: 0,[`pointRadius`]: 5,[`pointBorderColor`]: 'transparent',[`pointBackgroundColor`]: 'rgba(0,0,0,1)',[`pointStyle`]: 'circle',[`hidden`]: true });
  managerDataset = ({ [`label`]: (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_chart_manager')),[`data`]: [],[`backgroundColor`]: 'transparent',[`borderColor`]: 'rgb(0, 0, 0)',[`borderWidth`]: 2,[`fill`]: false,[`borderDash`]: [15],[`borderDashOffset`]: 5,[`pointRadius`]: 5,[`pointBorderColor`]: 'transparent',[`pointBackgroundColor`]: 'rgba(0,0,0,1)',[`pointStyle`]: 'triangle',[`hidden`]: true });
  var item_list = (getObjectProperty(___arguments.context.pageData, 'assessmentResult'));
  for (var item_index in item_list) {
    item = item_list[item_index];
    addItemToList(labelList, ([(getObjectProperty(item, 'topicId')),'. ',(getObjectProperty(item, 'topicName'))].join('')));
    if (typeof (getObjectProperty(item, 'averageOwn')) === 'number' && !isNaN((getObjectProperty(item, 'averageOwn')))) {
      addItemToList((getObjectProperty(ownDataset, 'data')), (getObjectProperty(item, 'averageOwn')));
    }
    if (typeof (getObjectProperty(item, 'averageColleague')) === 'number' && !isNaN((getObjectProperty(item, 'averageColleague')))) {
      addItemToList((getObjectProperty(colleagueDataset, 'data')), (getObjectProperty(item, 'averageColleague')));
    }
    if (typeof (getObjectProperty(item, 'averageEmployee')) === 'number' && !isNaN((getObjectProperty(item, 'averageEmployee')))) {
      addItemToList((getObjectProperty(employeeDataset, 'data')), (getObjectProperty(item, 'averageEmployee')));
    }
    if (typeof (getObjectProperty(item, 'averageManager')) === 'number' && !isNaN((getObjectProperty(item, 'averageManager')))) {
      addItemToList((getObjectProperty(managerDataset, 'data')), (getObjectProperty(item, 'averageManager')));
    }
  }
  ___arguments.context.pageData['chartLabels'] = labelList;
  datasets = [];
  if (!!(getObjectProperty(ownDataset, 'data')).length) {
    addItemToList(datasets, ownDataset);
  }
  if (!!(getObjectProperty(colleagueDataset, 'data')).length) {
    addItemToList(datasets, colleagueDataset);
  }
  if (!!(getObjectProperty(employeeDataset, 'data')).length) {
    addItemToList(datasets, employeeDataset);
  }
  if (!!(getObjectProperty(managerDataset, 'data')).length) {
    addItemToList(datasets, managerDataset);
  }
  await addColorRings();
  ___arguments.context.pageData['chartDatasets'] = datasets;
}

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}

/**
 * Describe this function...
 */
async function addColorRings() {
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('1', 14),[`backgroundColor`]: '#f9faf4',[`borderColor`]: 'transparent',[`fill`]: true,[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('2', 14),[`backgroundColor`]: '#CA4635',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('3', 14),[`backgroundColor`]: '#CC5137',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('4', 14),[`backgroundColor`]: '#CF5D38',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('5', 14),[`backgroundColor`]: '#E2A355',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('6', 14),[`backgroundColor`]: '#E8BB58',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('7', 14),[`backgroundColor`]: '#AFB553',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
  addItemToList(datasets, ({ [`label`]: 'hidden',[`data`]: listsRepeat('8', 14),[`backgroundColor`]: '#7EB361',[`borderColor`]: 'transparent',[`fill`]: '-1',[`pointRadius`]: 0 }));
}


  await BackendlessUI.Functions.Custom['fn_3a8bffe35f8d7e7c940c0d0e76ca4941'](___arguments.context.pageData, 'Instructor', ___arguments.context.appData);
  ___arguments.context.pageData['isOpenModal'] = false;
  ___arguments.context.pageData['isOpenAssessment'] = true;
  ___arguments.context.pageData['isOpenTop10'] = false;
  ___arguments.context.pageData['isOpenBottom10'] = false;
  ___arguments.context.pageData['tableSize'] = 20;
  ___arguments.context.pageData['pageSize'] = 20;
  ___arguments.context.pageData['pageOffset'] = 0;
  ___arguments.context.pageData['assessmentStats'] = null;
  ___arguments.context.pageData['assessmentResult'] = (await Backendless.Request.get(`${Backendless.appPath}/services/RelCo/getAssessmentResult`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'assessmentId': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'assessment.objectId'))) }).send());
  await prepareChart();
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (getObjectProperty(___arguments.context.pageData, 'assessmentResult')));
  if ((getObjectProperty(___arguments.context.pageData, 'assessment.completedColleague')) + (getObjectProperty(___arguments.context.pageData, 'assessment.completedEmployee')) + (getObjectProperty(___arguments.context.pageData, 'assessment.completedManager')) > 0) {
    ___arguments.context.pageData['assessmentStats'] = (await Backendless.Request.get(`${Backendless.appPath}/services/RelCo/getAssessmentStats`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'assessmentId': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'assessment.objectId'))) }).send());
  }
  if (!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3698e84fd22c6b0fb87c4c1d8518b06c')).length) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3698e84fd22c6b0fb87c4c1d8518b06c', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3705aa1ce11ec5984b24e2795418d043', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a2bdf0369f9e9b5c5432f209eabaf8f2', true);
  }
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'assessmentStats.top')))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d0b8153d12b1594e5122a125ad9ead19', true);
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4f3c9587cd6c694a8086dc34b1373ce7', (getObjectProperty(___arguments.context.pageData, 'assessmentStats.top')));
  }
  if (Array.isArray((getObjectProperty(___arguments.context.pageData, 'assessmentStats.bottom')))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e95a65ed05f585f18c3217d4f3b09e', true);
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('abfb21d2be79139c98cac34b14c5e8d3', (getObjectProperty(___arguments.context.pageData, 'assessmentStats.bottom')));
  }

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/tools_evaluate/components/ac9aa95413a6c62363f4fdf7ca64ecb4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/c3c68cf2952c7969850be0ebb5090456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_topic'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/e5ebaa8ac6c2782fbd86d50f855cafea/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var returnValue2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'assessment')), 'numOwnSingle')) == 1) {
    returnValue2 = 'tools_relco_own_single';
  } else {
    returnValue2 = 'tools_relco_own_manager';
  }

  return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c'](returnValue2))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/9165ae5fae27800a5cc8b1250f87ab6e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_average'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/58094fae35e52afa8f19e5d88b96f4fb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_colleague'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/3244f5a194b70a4f861b759d4e3f37b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_manager'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/6ce404793588f5dfb4d17a531a2e111f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_employee'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/3705aa1ce11ec5984b24e2795418d043/bundle.js', [], () => ({
  /* content */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
      if (!!((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('3698e84fd22c6b0fb87c4c1d8518b06c')).length) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, ___arguments['currentPage'])));
  }

  },
  /* handler:onPageChange */
  /* handler:onGoNext */
  async ['onGoNext'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, ((getObjectProperty(___arguments.context.pageData, 'currentPage')) + 1))));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goNextPage() })('3705aa1ce11ec5984b24e2795418d043');

  },
  /* handler:onGoNext */
  /* handler:onGoBack */
  async ['onGoBack'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, ((getObjectProperty(___arguments.context.pageData, 'currentPage')) - 1))));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goPreviousPage() })('3705aa1ce11ec5984b24e2795418d043');

  },
  /* handler:onGoBack */
  /* handler:onGoFirst */
  async ['onGoFirst'](___arguments) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, 1)));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goFirstPage() })('3705aa1ce11ec5984b24e2795418d043');

  },
  /* handler:onGoFirst */
  /* handler:onGoLast */
  async ['onGoLast'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3698e84fd22c6b0fb87c4c1d8518b06c', (await BackendlessUI.Functions.Custom['fn_521585cb30311713eddf039d55fae03d'](___arguments.context.pageData, null, null, null, null, null, null, null, null, null, null, null, null, (getObjectProperty(___arguments.context.pageData, 'pageCount')))));
  await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).goLastPage() })('3705aa1ce11ec5984b24e2795418d043');

  },
  /* handler:onGoLast */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'pageCount')) > 1 ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/86020d227835bb9655dcf6e27ee3e49b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('118e78cb148fed67e3116c1254944b89', ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('118e78cb148fed67e3116c1254944b89')), 'display')) ? false : true));
  if (((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')).includes('selected')) {
    removeItemInList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected', '');
  } else {
    addItemToList(((function (componentUid) { return ___arguments.context.getComponentClassesByUid(componentUid) })('f1f143c97e03aef1ae61b6964cab10fb')), 'selected');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools_evaluate/components/4ad5df910f26fc0fbf21f67ae3619d7c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'topicId')),'. ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('f1f143c97e03aef1ae61b6964cab10fb'), 'topicName'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/e83bd51689558082091a007f18b4bb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_subheader_name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/c3f83e153af8445ea1852d42f5385831/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_info'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/800e0f9ef96b5355957810b9db37c018/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenAssessment'] = (!(getObjectProperty(___arguments.context.pageData, 'isOpenAssessment')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/tools_evaluate/components/b2f743dd669c72cedb628bfccc1c6f7b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_topic'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/6bc3bc542ac9097a9818202522b7f9ac/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_question'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/6818237c06f2b3d7f80d4927f8943a43/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_score'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/ad5eb99c9a42e94e3e2431b5f27dc494/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_top10_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/6416f55383a46d9fd1ef76c56ccdfc0a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_bottom10_title'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/30b867c43f5537f385c21a63dbad474f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_question'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/10cb4b291434e656fb8fce5bd66748ad/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_topic'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/670c5fdeb2a138349d86d5bdb65aec46/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_assessment_score'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/tools_evaluate/components/e33bd8e374c9aeebcf1f1dd692b30367/bundle.js', [], () => ({
  /* content */
  /* handler:copyLabelLogic */
  async ['copyLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_release'))

  },
  /* handler:copyLabelLogic */
  /* handler:copiedLabelLogic */
  async ['copiedLabelLogic'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('tools_relco_button_release_copied'))

  },
  /* handler:copiedLabelLogic */
  /* handler:onCopy */
  async ['onCopy'](___arguments) {
      await ( async function (componentUid) {  ___arguments.context.getComponentByUid(componentUid).copy() })('e33bd8e374c9aeebcf1f1dd692b30367');

  },
  /* handler:onCopy */
  /* handler:contentLogic */
  async ['contentLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_a5d57ea77d7e8d1f69662c056f663e02']((getObjectProperty(___arguments.context.pageData, 'assessment.uniqueCode'))))

  },
  /* handler:contentLogic */
  /* content */
}))

define('./components/reusable/r_bcb31467df9dba89ed17ed5f77ff825c/logic/3273f784bbb772156f37c14bc66d3066/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('support', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2832f617432fbbad36d56db38b99f593/logic/b458c9a4bc627e894aa22ef6a6d29e4d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b8ee885c216cab297688fe5b3d272611'](null))

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })((await BackendlessUI.Functions.Custom['fn_27f111cc2ab2f4d4b84fe4fe372b9af2'](null)), undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2832f617432fbbad36d56db38b99f593/logic/dabfab7343619eed3dedb4efa568308c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b8ee885c216cab297688fe5b3d272611'](1))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e11eb88cdce49edd9b835522f752bf2e'](0))

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })((await BackendlessUI.Functions.Custom['fn_27f111cc2ab2f4d4b84fe4fe372b9af2'](1)), undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_2832f617432fbbad36d56db38b99f593/logic/50ce42334a0d53c23ef440b5b1ae42bc/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e11eb88cdce49edd9b835522f752bf2e'](0))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_2832f617432fbbad36d56db38b99f593/logic/160a318d43cd6a0800c3a67f875e158d/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e11eb88cdce49edd9b835522f752bf2e'](1))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_2832f617432fbbad36d56db38b99f593/logic/f06203b96ab8f1cbfec170b274964ed5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_b8ee885c216cab297688fe5b3d272611'](2))

  },
  /* handler:onContentAssignment */
  /* handler:onDisplayAssignment */
  async ['onDisplayAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_e11eb88cdce49edd9b835522f752bf2e'](1))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/b9c19466b205555f405d768e184133cc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('menu_logout'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/8161d5a2e717d703c0aeb7bbca3a842d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      if (await Backendless.UserService.isValidLogin()) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);
  } else {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://dynamicrelations.no', false, undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/307d95d85145a302b065609bd77efe6a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('admin', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    var displayAdmin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  displayAdmin = false;
  if (((getObjectProperty(___arguments.context.appData, 'currentUserRoles')).includes('Administrator')) && !(((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).startsWith('admin'))) {
    displayAdmin = true;
  }

  return displayAdmin

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('menu_administration'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/eaab859d3556c0cd35b919cbf918545c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var profileImage;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  profileImage = String(Backendless.appPath) + String('/files/images/profiles/default.svg');
  if (typeof (getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage')) === 'string') {
    profileImage = (getObjectProperty(___arguments.context.pageData, 'currentUser.profileImage'));
  }

  return profileImage

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/61ff6096f3611fe5b9c236d62aa956e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('profile', undefined);

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('menu_profile'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/0ce4d6e134aa66648245e28f7e717707/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('landing', undefined);

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((((function () { return BackendlessUI.Navigator.getCurrentPageName() })()).startsWith('admin')) && ((getObjectProperty(___arguments.context.appData, 'currentUserRoles')).includes('Instructor')))

  },
  /* handler:onDisplayAssignment */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('menu_home'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/0ac300ddc40778ff317f906a58644b49/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
      return ((Backendless.getCurrentUserToken()) ? true : false)

  },
  /* handler:onDisplayAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setTimeouts'); if (timerId && timers[timerId]) {    clearTimeout(timers[timerId]);    delete timers[timerId]; }}


  if ((getObjectProperty(___arguments.context.pageData, 'headerMenu')) == true) {
    stopSetTimeout('closeHeaderMenu');
    ___arguments.context.pageData['headerMenu'] = false;
  } else {
    ___arguments.context.pageData['headerMenu'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/841d564e6652c4e3e62155fb8f2da06c/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'headerMenu'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./components/reusable/r_309904c5e2c69b0b46c5c785aa642ad2/logic/f4613462cf1bcb586cae758e74298a63/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
      return (await BackendlessUI.Functions.Custom['fn_0bf6dc0c7267d782feb3acbfe09b8e7c']('menu_loggedinas'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

